// @flow

import React from 'react';
import styled, { keyframes } from 'styled-components';

import { WebsiteDefaultPage } from 'components/Page';
import { PageType } from 'Constants';
import { breakLine, flex, Width } from 'styles';

const METADATA = {
  title: 'Primary Club',
  description: `whee`,
};

const PAGE_TYPE = {
  type: PageType.TYPE.DEFAULT,
  product: PageType.PRODUCT.NONE,
};

const animateColor = keyframes`
  0% {
    fill: white;
  }
  25% {
    fill: #e4cce6;
  }
  50% {
    fill: #c2bcef;
  }
  75% {
    fill: #bacef2;
  }
  100% {
    fill: white;
  }
`;

const animateText = keyframes`
  0% {
    color: white;
  }
  25% {
    color: #e4cce6;
  }
  50% {
    color: #c2bcef;
  }
  75% {
    color: #bacef2;
  }
  100% {
    color: white;
  }
`;

const Footer = styled.div`
  padding: 20px 0;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  ${flex()};
  font-size: 1rem;
  line-height: 1;
  font-style: italic;
  color: white;
  animation: 20s linear 0s infinite reverse ${animateText};

  @media print, screen and (min-height: 820px) {
    position: fixed;
  }
`;

const Wrapper = styled.div`
  padding: 48px 0 60px 0;
  box-sizing: border-box;
  position: relative;

  ${flex()};
  flex-direction: column;

  svg {
    width: 174px;
    margin-bottom: 4.8rem;

    @media print, screen and (min-width: ${Width.BREAK_MD}px) {
      margin-bottom: 8rem;
    }

    rect,
    path {
      animation: 20s linear 0s infinite ${animateColor};
    }
  }

  p {
    color: white;
    font-size: 2.2rem;
    line-height: calc(26.4 / 22);
    font-style: italic;
    pointer-events: none;
    text-align: center;
    margin-bottom: 2.64rem;
    ${breakLine(false, true, true)};
    max-width: 400px;

    @media print, screen and (min-width: ${Width.BREAK_MD}px) {
      max-width: unset;
    }

    a {
      color: white;
      pointer-events: auto;
      animation: 20s linear 6s infinite reverse ${animateText};
    }

    &:nth-of-type(1) {
      animation: 20s linear 3s infinite reverse ${animateText};
    }

    &:nth-of-type(2) {
      animation: 20s linear 6s infinite reverse ${animateText};
    }

    &:nth-of-type(3) {
      animation: 20s linear 9s infinite reverse ${animateText};
    }

    &:nth-of-type(4) {
      animation: 20s linear 12s infinite reverse ${animateText};
    }
  }
`;

const PageContainer = () => {
  return (
    <WebsiteDefaultPage
      page={PAGE_TYPE}
      metaData={{
        ...METADATA,
      }}>
      <Wrapper>
        <svg viewBox="0 0 100 100">
          <g>
            {' '}
            <g>
              {' '}
              <path d="M41.2,30.1c1.2,0,2.2,0,3,0c1.3,0,2.4,0.3,3.4,0.8c1,0.6,1.8,1.4,2.3,2.5c0.5,1.1,0.8,2.4,0.8,3.7 c0,1.9-0.6,3.5-1.7,4.7c-1.1,1.2-2.4,1.8-4.1,1.8h-3.7V30.1z M66.8,63.8c-1.5,2-2.8,3.4-4,4.1c-1.2,0.7-2.6,1-4.2,1 c-1.8,0-3.5-0.4-4.9-1.3c-1.4-0.9-2.5-2.1-3.2-3.8c-0.7-1.6-1.1-3.6-1.1-5.8c0-2.7,0.4-5,1.1-6.8c0.8-1.8,1.8-3.2,3.1-4 c1.3-0.8,2.8-1.2,4.4-1.2c2,0,3.6,0.6,5,1.7c1.4,1.1,2.5,2.9,3.2,5.4h0.6l-0.6-8.4h-0.6c-0.1,0.6-0.4,1-0.7,1.4 c-0.2,0.2-0.5,0.3-0.9,0.3c-0.2,0-0.6-0.1-1.1-0.4c-1.7-0.9-3.5-1.3-5.3-1.3c-2.3,0-4.4,0.6-6.4,1.7c-1.9,1.1-3.5,2.7-4.6,4.8 c-1.1,2.1-1.7,4.3-1.7,6.8c0,0,0,0.1,0,0.1h-0.6c-1.2,0-2-0.3-2.6-1c-0.4-0.5-0.6-1.7-0.6-3.6v-8.3l5.1,0c3.1-0.1,5.3-0.8,7-2.3 c1.6-1.6,2.5-3.6,2.5-6.1c0-1.8-0.5-3.4-1.6-4.8c-1.1-1.3-2.4-2.3-4-2.9c-1.6-0.6-3.7-0.8-6.5-0.8H32.5v0.8h1.2c1.2,0,2,0.3,2.6,1 c0.4,0.5,0.6,1.7,0.6,3.6v19.8c0,1.7-0.2,2.9-0.5,3.3c-0.6,0.8-1.5,1.3-2.8,1.3h-1.2v0.8h12.6c0.2,2.7,1,5,2.5,7.1 c2.2,3,5.4,4.6,9.5,4.6c2.3,0,4.2-0.5,5.9-1.5c1.7-1,3.1-2.6,4.3-4.8L66.8,63.8z" />{' '}
              <path d="M79.2,21.1c7.7,7.8,11.9,18,11.9,28.9c0,10.9-4.2,21.2-11.9,28.9V21.1z M50,91.1c-10.9,0-21.2-4.2-28.9-11.9 h57.9C71.2,86.9,60.9,91.1,50,91.1 M8.9,50c0-10.9,4.2-21.2,11.9-29v30.9h-0.9v0.3h0.9V79C13.1,71.2,8.9,60.9,8.9,50 M50,8.9 c10.9,0,21.2,4.2,29,11.9H21C28.8,13.1,39.1,8.9,50,8.9 M79.1,69v10H69L79.1,69z M79.1,31.2L68.8,20.9h10.2V31.2z M31.2,79.1 L20.9,68.9V31.4l10.4-10.4h37.3l10.4,10.4v37.5L68.9,79.1H31.2z M20.9,69l10,10h-10V69z M20.9,20.9h10.2L20.9,31.2V20.9z M79.2,20.9L79.2,20.9L79.2,20.9C71.4,13,61,8.7,50,8.7c-11,0-21.4,4.3-29.2,12.1h0v0C13,28.6,8.7,39,8.7,50 c0,11,4.3,21.4,12.1,29.2v0.1h0.1C28.6,87,39,91.3,50,91.3c11,0,21.4-4.3,29.1-12.1h0.1v-0.1C87,71.4,91.3,61,91.3,50 C91.3,39,87,28.6,79.2,20.9" />{' '}
              <path d="M28.4,8.3c0.2-0.1,0.3-0.1,0.5-0.1c0.2,0,0.3,0.1,0.4,0.2c0.1,0.1,0.2,0.2,0.3,0.4c0.1,0.2,0.2,0.5,0.1,0.7 c-0.1,0.2-0.2,0.4-0.4,0.5c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0-0.2,0.1-0.3,0.1l-0.8-1.6C28.2,8.5,28.3,8.4,28.4,8.3 M27.1,8.9 c0.1-0.1,0.3-0.1,0.4,0c0.1,0,0.2,0.2,0.3,0.4l1.2,2.4c0.1,0.2,0.2,0.4,0.2,0.4c0,0.1-0.1,0.2-0.3,0.3l-0.1,0.1l0.1,0.1l1.6-0.8 l-0.1-0.1l-0.1,0.1c-0.1,0.1-0.3,0.1-0.4,0c-0.1,0-0.2-0.2-0.3-0.4L29,10.3c0.1,0,0.3-0.1,0.4-0.1c0.1,0,0.2-0.1,0.3-0.1 c0.4-0.2,0.6-0.4,0.7-0.7c0.1-0.3,0.1-0.6-0.1-0.9C30.2,8.2,30,8,29.8,7.9c-0.2-0.1-0.4-0.1-0.7-0.1c-0.2,0-0.5,0.1-0.8,0.3 l-1.4,0.7L26.9,9L27.1,8.9z" />{' '}
              <path d="M31.9,6.7c0.3-0.1,0.5-0.1,0.7,0c0.2,0.1,0.4,0.3,0.5,0.5c0.1,0.2,0.1,0.5,0,0.7c-0.1,0.2-0.4,0.4-0.7,0.6 c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0l-0.7-1.6C31.7,6.8,31.8,6.7,31.9,6.7 M30.5,7.2c0.1-0.1,0.3-0.1,0.4,0c0.1,0,0.2,0.2,0.3,0.4 l1,2.5c0.1,0.2,0.1,0.4,0.1,0.4c0,0.1-0.1,0.2-0.3,0.3l-0.1,0.1l0,0.1l1.7-0.7l0-0.1l-0.2,0.1c-0.1,0.1-0.3,0.1-0.4,0 c-0.1,0-0.2-0.2-0.3-0.4l-0.5-1.1c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0.1,0,0.1-0.1,0.2-0.1l2.1,1.3l1-0.4l0-0.1 c-0.2,0.1-0.4,0.1-0.6,0.1c-0.2,0-0.4-0.1-0.7-0.3l-1.3-0.8c0.3-0.2,0.5-0.4,0.6-0.7c0.1-0.2,0.1-0.5,0-0.7 c-0.1-0.2-0.2-0.4-0.4-0.5c-0.2-0.1-0.4-0.1-0.6-0.1c-0.2,0-0.5,0.1-1,0.3l-1.4,0.6l0,0.1L30.5,7.2z" />{' '}
              <path d="M34.7,5.5c0.1,0,0.2,0,0.3,0c0.1,0,0.1,0,0.2,0.1c0,0.1,0.1,0.2,0.2,0.4l0.8,2.6c0.1,0.2,0.1,0.4,0.1,0.5 c0,0.1-0.1,0.2-0.3,0.3l-0.1,0l0,0.1l1.7-0.6l0-0.1l-0.1,0c-0.1,0-0.2,0-0.3,0c-0.1,0-0.1,0-0.2-0.1c0-0.1-0.1-0.2-0.2-0.4 l-0.8-2.6c-0.1-0.2-0.1-0.4-0.1-0.5c0-0.1,0.1-0.2,0.3-0.3l0.1,0l0-0.1l-1.7,0.6l0,0.1L34.7,5.5z" />{' '}
              <path d="M37.1,4.7c0.1,0,0.1,0.1,0.2,0.1c0.1,0.1,0.1,0.2,0.2,0.4l0.6,2.7c0.1,0.2,0.1,0.4,0,0.5 c0,0.1-0.2,0.2-0.3,0.2l-0.1,0l0,0.1l1.4-0.3l0-0.1l-0.1,0c-0.2,0-0.3,0-0.4,0c-0.1,0-0.1-0.2-0.2-0.5l-0.6-2.7l2.4,3.1l0.1,0 L41,4.4l0.6,2.7c0.1,0.2,0.1,0.4,0,0.5c0,0.1-0.2,0.2-0.3,0.2l-0.1,0l0,0.1l1.7-0.4l0-0.1l-0.1,0c-0.2,0-0.3,0-0.4,0 c-0.1,0-0.1-0.2-0.2-0.5l-0.6-2.7c-0.1-0.2-0.1-0.4,0-0.5c0-0.1,0.2-0.2,0.3-0.2l0.1,0l0-0.1l-1.2,0.3l-0.7,3.5l-2.2-2.9l-1.2,0.3 l0,0.1C36.9,4.7,37,4.7,37.1,4.7" />{' '}
              <path d="M44.7,4l0.9,1.6l-1.4,0.2L44.7,4z M44.3,7.4l0-0.1c-0.2,0-0.3,0-0.4,0c-0.1,0-0.1-0.1-0.1-0.2 c0-0.1,0-0.2,0.1-0.4L44.1,6l1.6-0.2L46,6.4c0.1,0.2,0.2,0.3,0.2,0.4c0,0.1,0,0.1-0.1,0.2c-0.1,0.1-0.2,0.1-0.3,0.1l0,0.1l1.7-0.2 l0-0.1c-0.2,0-0.3,0-0.4-0.1c-0.1-0.1-0.2-0.2-0.4-0.5L44.8,3l-0.1,0l-1.1,3.6c-0.1,0.3-0.2,0.5-0.3,0.6c0,0.1-0.2,0.1-0.3,0.2 l0,0.1L44.3,7.4z" />{' '}
              <path d="M49.2,3.1c0.3,0,0.5,0.1,0.7,0.2C50,3.5,50.1,3.7,50.1,4c0,0.3-0.1,0.5-0.3,0.7c-0.2,0.2-0.5,0.3-0.9,0.3 c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0l0-1.8C48.9,3.1,49.1,3.1,49.2,3.1 M47.6,3c0.2,0,0.3,0,0.4,0.1c0.1,0.1,0.1,0.2,0.1,0.5l0,2.7 c0,0.2,0,0.4-0.1,0.5C48,6.9,47.9,7,47.7,7l-0.2,0l0,0.1l1.8,0l0-0.1l-0.2,0c-0.2,0-0.3,0-0.4-0.1c-0.1-0.1-0.1-0.2-0.1-0.5l0-1.2 c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0.1,0,0.2,0,0.3,0l1.4,2l1.1,0l0-0.1c-0.2,0-0.4-0.1-0.6-0.2c-0.1-0.1-0.3-0.3-0.5-0.5L49.8,5 c0.4-0.1,0.6-0.2,0.8-0.4c0.2-0.2,0.2-0.4,0.2-0.7c0-0.2-0.1-0.4-0.2-0.6C50.4,3.1,50.2,3,50,2.9c-0.2-0.1-0.5-0.1-1-0.1l-1.5,0 l0,0.1L47.6,3z" />{' '}
              <path d="M52,3.2c0,0.1,0.1,0.2,0.3,0.4l1,1.8l-0.1,1c0,0.2,0,0.4-0.1,0.4C53.1,7,52.9,7,52.7,7l-0.1,0l0,0.1l1.8,0.1 l0-0.1l-0.1,0c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.2-0.1-0.5l0.1-1.1L55,3.9c0.1-0.2,0.3-0.3,0.3-0.4c0.1-0.1,0.2-0.1,0.3-0.2 c0.1,0,0.2-0.1,0.2-0.1l0.1,0l0-0.1L54.5,3l0,0.1c0.1,0,0.2,0,0.2,0.1c0.1,0,0.1,0.1,0.1,0.1c0,0,0,0.1,0,0.2 c0,0.1-0.1,0.2-0.2,0.4l-0.9,1.2L53,3.7c-0.1-0.2-0.2-0.3-0.2-0.4c0-0.1,0.1-0.2,0.1-0.2c0.1,0,0.2-0.1,0.3,0l0.1,0l0-0.1 l-1.8-0.1l0,0.1c0.1,0,0.1,0,0.2,0C51.9,3.1,52,3.2,52,3.2" />{' '}
              <path d="M59.2,8.1c0.4,0.1,0.7,0.1,1,0c0.3-0.1,0.6-0.3,0.9-0.6L61,7.4c-0.3,0.3-0.6,0.5-0.8,0.5 C60,8,59.7,8,59.5,7.9c-0.3-0.1-0.6-0.2-0.8-0.4c-0.2-0.2-0.3-0.4-0.4-0.7c-0.1-0.3-0.1-0.6,0-1c0.1-0.5,0.2-0.8,0.4-1.1 c0.2-0.3,0.4-0.5,0.7-0.6c0.3-0.1,0.5-0.1,0.8,0c0.3,0.1,0.6,0.2,0.8,0.5c0.2,0.2,0.3,0.6,0.3,1l0.1,0l0.2-1.4l-0.1,0 c0,0.1-0.1,0.2-0.2,0.2c0,0-0.1,0-0.2,0c0,0-0.1,0-0.2-0.1c-0.3-0.2-0.5-0.3-0.8-0.4c-0.4-0.1-0.8-0.1-1.1,0.1 c-0.4,0.1-0.7,0.3-0.9,0.6c-0.3,0.3-0.4,0.7-0.5,1.1c-0.1,0.5-0.1,1,0.1,1.4C58,7.6,58.5,8,59.2,8.1" />{' '}
              <path d="M61.3,8.4l-0.2,0l0,0.1l3.1,1l0.7-1l-0.1-0.1c-0.2,0.2-0.3,0.4-0.5,0.5C64.2,9,64.1,9,64,9 c-0.1,0-0.4,0-0.7-0.1L63,8.8c-0.3-0.1-0.4-0.1-0.5-0.2c0,0-0.1-0.1-0.1-0.1c0-0.1,0-0.2,0.1-0.4l0.8-2.5c0.1-0.2,0.1-0.4,0.2-0.4 c0.1-0.1,0.1-0.1,0.2-0.1c0.1,0,0.3,0,0.5,0.1l0-0.1l-1.8-0.6l0,0.1l0.2,0c0.2,0,0.3,0.1,0.3,0.2c0,0.1,0,0.2-0.1,0.5L62,7.9 c-0.1,0.2-0.1,0.4-0.2,0.4C61.7,8.5,61.5,8.5,61.3,8.4" />{' '}
              <path d="M66.7,10.5c0.4,0.2,0.7,0.2,1,0.2c0.3-0.1,0.5-0.2,0.7-0.4c0.2-0.2,0.3-0.5,0.5-0.9l0.7-1.6 c0.1-0.2,0.2-0.3,0.2-0.4c0.1-0.1,0.3-0.1,0.4,0l0.1,0.1l0-0.1l-1.4-0.6l0,0.1L69.2,7c0.1,0.1,0.2,0.2,0.3,0.3 c0,0.1,0,0.2-0.1,0.5l-0.7,1.6c-0.2,0.4-0.3,0.7-0.5,0.8c-0.1,0.1-0.3,0.2-0.6,0.3c-0.2,0-0.5,0-0.8-0.1c-0.2-0.1-0.4-0.2-0.5-0.3 c-0.1-0.1-0.2-0.3-0.2-0.4c0-0.1,0-0.3,0-0.5c0.1-0.2,0.1-0.4,0.2-0.5l0.7-1.7c0.1-0.2,0.2-0.4,0.3-0.4c0.1-0.1,0.2-0.1,0.4,0 L68,6.5l0-0.1l-1.7-0.7l0,0.1l0.1,0.1c0.1,0.1,0.2,0.1,0.3,0.2c0,0.1,0,0.3-0.1,0.5L66,8.1c-0.2,0.5-0.3,0.8-0.3,1 c0,0.3,0.1,0.5,0.2,0.8C66,10.2,66.3,10.4,66.7,10.5" />{' '}
              <path d="M72.4,8.7c0.3,0.2,0.6,0.4,0.6,0.7c0.1,0.2,0.1,0.5,0,0.7c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.3,0.1-0.5,0.1 c-0.2,0-0.4-0.1-0.6-0.2c-0.1-0.1-0.2-0.1-0.3-0.2c-0.1-0.1-0.2-0.1-0.2-0.2l0.8-1.4C72,8.5,72.2,8.6,72.4,8.7 M72.1,11.9 c-0.1,0.2-0.3,0.4-0.6,0.4c-0.3,0.1-0.5,0-0.9-0.2c-0.2-0.1-0.4-0.3-0.6-0.4l0.8-1.5c0.1,0,0.1,0,0.2,0.1c0.1,0,0.2,0.1,0.3,0.2 c0.3,0.1,0.5,0.3,0.6,0.5c0.1,0.2,0.2,0.3,0.2,0.5C72.2,11.6,72.2,11.7,72.1,11.9 M71.2,8.1c0,0.1,0,0.2-0.2,0.5L69.8,11 c-0.1,0.2-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0-0.4,0l-0.1-0.1l-0.1,0.1l1.7,1c0.4,0.2,0.7,0.4,1,0.4c0.2,0,0.5,0,0.7-0.1 c0.2-0.1,0.4-0.2,0.5-0.4c0.1-0.2,0.2-0.5,0.1-0.8c-0.1-0.2-0.2-0.4-0.4-0.6c0.3,0,0.5,0,0.7-0.1c0.2-0.1,0.3-0.2,0.4-0.4 c0.1-0.2,0.2-0.4,0.1-0.7c-0.1-0.2-0.2-0.5-0.4-0.7c-0.2-0.1-0.4-0.3-0.7-0.5l-1.6-0.9l-0.1,0.1L71,7.9C71.1,7.9,71.2,8,71.2,8.1" />{' '}
              <path d="M71.8,91.5c-0.2,0.1-0.3,0.1-0.5,0.1c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.3-0.2-0.3-0.4 c-0.1-0.2-0.2-0.5-0.1-0.7c0.1-0.2,0.2-0.4,0.4-0.5c0.1,0,0.1-0.1,0.2-0.1c0.1,0,0.2-0.1,0.3-0.1l0.8,1.6 C72,91.4,71.9,91.5,71.8,91.5 M73.1,91c-0.1,0.1-0.3,0.1-0.4,0c-0.1,0-0.2-0.2-0.3-0.4l-1.3-2.4C71.1,88,71,87.9,71,87.8 c0-0.1,0.1-0.2,0.3-0.3l0.1-0.1l-0.1-0.1l-1.6,0.8l0.1,0.1l0.1-0.1c0.1-0.1,0.3-0.1,0.4,0c0.1,0,0.2,0.2,0.3,0.4l0.6,1.1 c-0.1,0-0.3,0.1-0.4,0.1c-0.1,0-0.2,0.1-0.3,0.1c-0.4,0.2-0.6,0.4-0.7,0.7c-0.1,0.3-0.1,0.6,0.1,0.9c0.1,0.2,0.3,0.4,0.5,0.5 c0.2,0.1,0.4,0.1,0.7,0.1c0.2,0,0.5-0.1,0.8-0.3l1.4-0.7l-0.1-0.1L73.1,91z" />{' '}
              <path d="M68.3,93.2c-0.3,0.1-0.5,0.1-0.7,0c-0.2-0.1-0.4-0.3-0.5-0.5c-0.1-0.2-0.1-0.5,0-0.7c0.1-0.2,0.4-0.4,0.7-0.6 c0,0,0,0,0.1,0c0,0,0.1,0,0.1,0l0.7,1.6C68.6,93.1,68.4,93.2,68.3,93.2 M69.8,92.7c-0.1,0.1-0.3,0.1-0.4,0c-0.1,0-0.2-0.2-0.3-0.4 l-1-2.5c-0.1-0.2-0.1-0.4-0.1-0.4c0-0.1,0.1-0.2,0.3-0.3l0.1-0.1l0-0.1l-1.7,0.7l0,0.1l0.2-0.1c0.1-0.1,0.3-0.1,0.4,0 c0.1,0,0.2,0.2,0.3,0.4l0.5,1.1c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c-0.1,0-0.1,0.1-0.2,0.1l-2.1-1.3l-1,0.4l0,0.1 c0.2-0.1,0.4-0.1,0.6-0.1c0.2,0,0.4,0.1,0.7,0.3l1.3,0.8c-0.3,0.2-0.5,0.4-0.6,0.7c-0.1,0.2-0.1,0.5,0,0.7 c0.1,0.2,0.2,0.4,0.4,0.5c0.2,0.1,0.4,0.1,0.6,0.1c0.2,0,0.5-0.1,1-0.3l1.4-0.6l0-0.1L69.8,92.7z" />{' '}
              <path d="M65.6,94.4c-0.1,0-0.2,0-0.3,0c-0.1,0-0.1,0-0.2-0.1c0-0.1-0.1-0.2-0.2-0.4l-0.9-2.6C64,91.1,64,91,64,90.9 c0-0.1,0.1-0.2,0.3-0.3l0.1,0l0-0.1L62.7,91l0,0.1l0.1,0c0.1,0,0.2,0,0.3,0c0.1,0,0.1,0,0.2,0.1c0,0.1,0.1,0.2,0.2,0.4l0.9,2.6 c0.1,0.2,0.1,0.4,0.1,0.5c0,0.1-0.1,0.2-0.3,0.3l-0.1,0l0,0.1l1.7-0.6l0-0.1L65.6,94.4z" />{' '}
              <path d="M63.1,95.2c-0.1,0-0.1-0.1-0.2-0.1c-0.1-0.1-0.1-0.2-0.2-0.4L62.1,92c-0.1-0.2-0.1-0.4,0-0.5 c0-0.1,0.2-0.2,0.3-0.2l0.1,0l0-0.1l-1.4,0.3l0,0.1l0.1,0c0.2,0,0.3,0,0.4,0c0.1,0,0.1,0.2,0.2,0.5l0.6,2.7l-2.4-3.1l-0.1,0 l-0.8,3.8l-0.6-2.7c-0.1-0.2-0.1-0.4,0-0.5c0-0.1,0.2-0.2,0.3-0.2l0.1,0l0-0.1l-1.7,0.4l0,0.1l0.1,0c0.2,0,0.3,0,0.4,0 c0.1,0,0.1,0.2,0.2,0.5l0.6,2.7c0.1,0.2,0.1,0.4,0,0.5c0,0.1-0.2,0.2-0.3,0.2l-0.1,0l0,0.1l1.2-0.3l0.7-3.6l2.2,2.9l1.2-0.3l0-0.1 C63.3,95.2,63.2,95.2,63.1,95.2" />{' '}
              <path d="M55.6,96l-0.9-1.6l1.4-0.2L55.6,96z M55.9,92.6l0,0.1c0.2,0,0.3,0,0.4,0c0.1,0,0.1,0.1,0.1,0.2 c0,0.1,0,0.2-0.1,0.4l-0.2,0.7l-1.6,0.2l-0.3-0.6c-0.1-0.2-0.2-0.3-0.2-0.4c0-0.1,0-0.1,0.1-0.2c0.1-0.1,0.2-0.1,0.3-0.1l0-0.1 l-1.6,0.2l0,0.1c0.2,0,0.3,0,0.4,0.1c0.1,0.1,0.2,0.2,0.4,0.5l1.9,3.3l0.1,0l1.1-3.6c0.1-0.3,0.2-0.5,0.3-0.6 c0-0.1,0.2-0.1,0.3-0.2l0-0.1L55.9,92.6z" />{' '}
              <path d="M51.1,96.9c-0.3,0-0.5-0.1-0.7-0.2c-0.2-0.2-0.3-0.4-0.3-0.6c0-0.3,0.1-0.5,0.3-0.7c0.2-0.2,0.5-0.3,0.9-0.3 c0,0,0,0,0.1,0c0,0,0.1,0,0.1,0l0,1.8C51.4,96.9,51.2,96.9,51.1,96.9 M52.6,97c-0.2,0-0.3,0-0.4-0.1c-0.1-0.1-0.1-0.2-0.1-0.5 l0-2.7c0-0.2,0-0.4,0.1-0.5c0.1-0.1,0.2-0.2,0.4-0.2l0.2,0l0-0.1l-1.8,0l0,0.1l0.2,0c0.2,0,0.3,0,0.4,0.1c0.1,0.1,0.1,0.2,0.1,0.5 l0,1.2c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c-0.1,0-0.2,0-0.3,0L49.6,93l-1.1,0l0,0.1c0.2,0,0.4,0.1,0.6,0.2c0.1,0.1,0.3,0.3,0.5,0.5 l0.9,1.2c-0.4,0.1-0.6,0.2-0.8,0.4c-0.2,0.2-0.2,0.4-0.2,0.7c0,0.2,0.1,0.4,0.2,0.6c0.2,0.2,0.3,0.3,0.5,0.4 c0.2,0.1,0.5,0.1,1,0.1l1.5,0l0-0.1L52.6,97z" />{' '}
              <path d="M48.2,96.8c0-0.1-0.1-0.2-0.3-0.4l-1-1.8l0.1-1c0-0.2,0-0.4,0.1-0.4c0.1-0.1,0.2-0.1,0.4-0.1l0.1,0l0-0.1 l-1.8-0.1l0,0.1l0.1,0c0.2,0,0.3,0.1,0.4,0.2c0.1,0.1,0.1,0.2,0.1,0.5l-0.1,1.1l-1.1,1.5c-0.1,0.2-0.2,0.3-0.3,0.4 c-0.1,0.1-0.2,0.1-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.1l-0.1,0l0,0.1l1.5,0.1l0-0.1c-0.1,0-0.2,0-0.2-0.1c-0.1,0-0.1-0.1-0.1-0.1 c0,0,0-0.1,0-0.2c0-0.1,0.1-0.2,0.2-0.4l0.9-1.2l0.8,1.4c0.1,0.2,0.2,0.3,0.2,0.4c0,0.1-0.1,0.2-0.1,0.2c-0.1,0-0.2,0.1-0.3,0.1 l-0.1,0l0,0.1l1.8,0.1l0-0.1c-0.1,0-0.1,0-0.2,0C48.4,96.9,48.3,96.9,48.2,96.8" />{' '}
              <path d="M41,91.9c-0.4-0.1-0.7-0.1-1,0.1c-0.3,0.1-0.6,0.3-0.9,0.7l0.1,0.1c0.3-0.3,0.6-0.5,0.8-0.5 c0.2-0.1,0.5-0.1,0.7,0c0.3,0.1,0.6,0.2,0.8,0.4c0.2,0.2,0.3,0.4,0.4,0.7c0.1,0.3,0.1,0.6,0,1c-0.1,0.5-0.2,0.8-0.4,1.1 c-0.2,0.3-0.4,0.5-0.7,0.6C40.6,96,40.3,96,40,96c-0.3-0.1-0.6-0.2-0.8-0.5c-0.2-0.2-0.3-0.6-0.3-1l-0.1,0l-0.2,1.4l0.1,0 c0-0.1,0.1-0.2,0.2-0.2c0,0,0.1,0,0.2,0c0,0,0.1,0,0.2,0.1c0.3,0.2,0.5,0.3,0.8,0.4c0.4,0.1,0.8,0.1,1.1-0.1 c0.4-0.1,0.7-0.3,0.9-0.6c0.3-0.3,0.4-0.7,0.5-1.1c0.1-0.5,0.1-1-0.1-1.4C42.2,92.4,41.7,92,41,91.9" />{' '}
              <path d="M38.9,91.6l0.2,0l0-0.1l-3.1-1l-0.7,1l0.1,0.1c0.2-0.2,0.3-0.4,0.5-0.5c0.1-0.1,0.3-0.1,0.4-0.1 c0.1,0,0.4,0,0.7,0.1l0.3,0.1c0.3,0.1,0.4,0.1,0.5,0.2c0,0,0.1,0.1,0.1,0.1c0,0.1,0,0.2-0.1,0.4l-0.8,2.5 c-0.1,0.2-0.1,0.4-0.2,0.4C36.7,95,36.6,95,36.5,95c-0.1,0-0.3,0-0.5-0.1l0,0.1l1.8,0.6l0-0.1l-0.2,0c-0.2,0-0.3-0.1-0.3-0.2 c0-0.1,0-0.2,0.1-0.5l0.8-2.6c0.1-0.2,0.1-0.4,0.2-0.4C38.6,91.6,38.7,91.6,38.9,91.6" />{' '}
              <path d="M33.5,89.5c-0.4-0.2-0.7-0.2-1-0.2c-0.3,0.1-0.5,0.2-0.7,0.4c-0.2,0.2-0.3,0.5-0.5,0.9l-0.7,1.6 c-0.1,0.2-0.2,0.3-0.2,0.4c-0.1,0.1-0.3,0.1-0.4,0l-0.1-0.1l0,0.1l1.4,0.6l0-0.1L31,93.1c-0.1-0.1-0.2-0.1-0.3-0.3 c0-0.1,0-0.2,0.1-0.5l0.7-1.6c0.2-0.4,0.3-0.7,0.5-0.8c0.1-0.1,0.3-0.2,0.6-0.3c0.2,0,0.5,0,0.8,0.1c0.2,0.1,0.4,0.2,0.5,0.3 c0.1,0.1,0.2,0.3,0.2,0.4c0,0.1,0,0.3,0,0.5c-0.1,0.2-0.1,0.4-0.2,0.5l-0.7,1.7c-0.1,0.2-0.2,0.4-0.3,0.4c-0.1,0.1-0.2,0.1-0.4,0 l-0.1-0.1l0,0.1l1.7,0.7l0-0.1l-0.1-0.1c-0.1-0.1-0.2-0.1-0.3-0.2c0-0.1,0-0.3,0.1-0.5l0.6-1.5c0.2-0.5,0.3-0.8,0.3-1 c0-0.3-0.1-0.5-0.2-0.8C34.2,89.9,33.9,89.7,33.5,89.5" />{' '}
              <path d="M27.9,91.4c-0.3-0.2-0.6-0.4-0.6-0.6c-0.1-0.2-0.1-0.5,0-0.7c0.1-0.1,0.2-0.3,0.3-0.3 c0.1-0.1,0.3-0.1,0.4-0.1c0.2,0,0.4,0.1,0.6,0.2c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0.1,0.2,0.1,0.2,0.2l-0.8,1.4 C28.2,91.6,28,91.5,27.9,91.4 M28.1,88.2c0.1-0.2,0.3-0.4,0.6-0.4c0.3-0.1,0.5,0,0.9,0.2c0.2,0.1,0.4,0.2,0.6,0.4l-0.8,1.5 c-0.1,0-0.1,0-0.2-0.1c-0.1,0-0.2-0.1-0.3-0.2c-0.3-0.1-0.5-0.3-0.6-0.5c-0.1-0.2-0.2-0.3-0.2-0.5C28,88.5,28,88.4,28.1,88.2 M29,92c0-0.1,0-0.2,0.2-0.5l1.3-2.4c0.1-0.2,0.2-0.3,0.3-0.4c0.1-0.1,0.3-0.1,0.4,0l0.1,0.1l0.1-0.1l-1.7-0.9 c-0.4-0.2-0.7-0.4-1-0.4c-0.2,0-0.5,0-0.7,0.1c-0.2,0.1-0.4,0.2-0.5,0.4c-0.1,0.2-0.2,0.5-0.1,0.8c0.1,0.2,0.2,0.4,0.4,0.6 c-0.3,0-0.5,0-0.7,0.1c-0.2,0.1-0.3,0.2-0.4,0.4c-0.1,0.2-0.1,0.4-0.1,0.7c0.1,0.2,0.2,0.5,0.4,0.7c0.2,0.1,0.4,0.3,0.7,0.5 l1.6,0.9l0.1-0.1l-0.1-0.1C29.1,92.2,29,92.1,29,92" />{' '}
              <path d="M9.1,72.9c-0.1-0.1-0.2-0.2-0.2-0.3c-0.1-0.2-0.1-0.3-0.1-0.5c0-0.2,0.1-0.3,0.2-0.4c0.1-0.1,0.2-0.3,0.4-0.3 c0.2-0.1,0.5-0.2,0.7-0.1c0.2,0.1,0.4,0.2,0.5,0.4c0,0.1,0.1,0.1,0.1,0.2c0,0.1,0.1,0.2,0.1,0.3L9.1,72.9z M12.2,70.5l0.1,0.1 c0.1,0.1,0.1,0.3,0.1,0.4c0,0.1-0.2,0.2-0.4,0.3L10.8,72c0-0.1-0.1-0.3-0.1-0.4c0-0.1-0.1-0.2-0.1-0.3c-0.2-0.4-0.4-0.6-0.7-0.7 c-0.3-0.1-0.6-0.1-0.9,0.1c-0.2,0.1-0.4,0.3-0.5,0.5c-0.1,0.2-0.1,0.4-0.1,0.7c0,0.2,0.1,0.5,0.3,0.8l0.7,1.4L9.5,74l-0.1-0.1 c-0.1-0.1-0.1-0.3-0.1-0.4c0-0.1,0.2-0.2,0.4-0.3l2.4-1.3c0.2-0.1,0.4-0.2,0.4-0.2c0.1,0,0.3,0.1,0.3,0.3l0.1,0.1l0.1-0.1 l-0.9-1.6L12.2,70.5z" />{' '}
              <path d="M7.4,69.5c-0.1-0.2-0.2-0.3-0.2-0.4c-0.1-0.2-0.1-0.5,0-0.7c0.1-0.2,0.2-0.4,0.5-0.5c0.2-0.1,0.5-0.1,0.7,0 c0.2,0.1,0.4,0.4,0.6,0.7c0,0,0,0,0,0.1c0,0,0,0.1,0,0.1L7.4,69.5z M8,69.9l2.5-1.1c0.2-0.1,0.4-0.1,0.4-0.1 c0.1,0,0.2,0.1,0.3,0.3l0.1,0.1l0.1,0l-0.7-1.7l-0.1,0l0.1,0.2c0.1,0.1,0.1,0.3,0,0.4c0,0.1-0.2,0.2-0.4,0.3l-1.1,0.5 c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0-0.1-0.1-0.1-0.1-0.2l1.2-2.1l-0.5-1l-0.1,0c0.1,0.2,0.1,0.4,0.1,0.6c0,0.2-0.1,0.4-0.3,0.7 l-0.8,1.3c-0.2-0.3-0.4-0.5-0.7-0.6c-0.2-0.1-0.5-0.1-0.7,0c-0.2,0.1-0.4,0.2-0.5,0.5c-0.1,0.2-0.1,0.4-0.1,0.6 c0,0.2,0.1,0.5,0.3,0.9l0.6,1.4l0.1,0l-0.1-0.1c-0.1-0.1-0.1-0.3,0-0.4C7.7,70.1,7.8,70,8,69.9" />{' '}
              <path d="M9.4,64.8c0.1,0,0.2,0.1,0.3,0.3l0,0.1l0.1,0l-0.6-1.7l-0.1,0l0.1,0.1c0,0.1,0,0.2,0,0.3c0,0.1,0,0.1-0.1,0.2 c-0.1,0-0.2,0.1-0.4,0.2l-2.6,0.9c-0.2,0.1-0.4,0.1-0.5,0.1c-0.1,0-0.2-0.1-0.3-0.3l-0.1-0.1l-0.1,0l0.6,1.7l0.1,0l0-0.1 c0-0.1,0-0.2,0-0.3c0-0.1,0-0.1,0.1-0.2c0.1,0,0.2-0.1,0.4-0.2l2.6-0.9C9.1,64.8,9.3,64.7,9.4,64.8" />{' '}
              <path d="M5.2,63.8c0.1-0.1,0.2-0.1,0.4-0.2l2.7-0.7c0.2-0.1,0.4-0.1,0.5,0c0.1,0,0.2,0.2,0.3,0.3l0,0.1l0.1,0 l-0.4-1.4l-0.1,0l0,0.1c0,0.2,0,0.3,0,0.4c0,0.1-0.2,0.1-0.4,0.2l-2.7,0.7l3-2.5l0-0.1l-3.8-0.7l2.7-0.7c0.2-0.1,0.4-0.1,0.5,0 c0.1,0,0.2,0.2,0.3,0.3l0,0.1l0.1,0l-0.4-1.7l-0.1,0l0,0.1c0,0.2,0,0.3,0,0.4c0,0.1-0.2,0.1-0.4,0.2l-2.7,0.7 c-0.2,0.1-0.4,0.1-0.5,0c-0.1,0-0.2-0.2-0.3-0.3l0-0.1l-0.1,0L4,60.3l3.6,0.6l-2.8,2.3L5,64.4l0.1,0c0-0.2-0.1-0.3,0-0.4 C5.1,63.9,5.1,63.8,5.2,63.8" />{' '}
              <path d="M4.2,56.4l1.6-0.9l0.2,1.4L4.2,56.4z M7.5,57.7c0.1,0,0.1,0.2,0.2,0.3l0.1,0l-0.2-1.3l-0.1,0 c0,0.2,0,0.3,0,0.4c0,0.1-0.1,0.1-0.2,0.1c-0.1,0-0.2,0-0.4-0.1L6.2,57L6,55.4L6.5,55c0.2-0.1,0.3-0.2,0.4-0.2 c0.1,0,0.1,0,0.2,0.1c0.1,0.1,0.1,0.2,0.1,0.3l0.1,0l-0.2-1.6l-0.1,0c0,0.2,0,0.3-0.1,0.4c-0.1,0.1-0.2,0.2-0.5,0.4l-3.3,1.9 l0,0.1l3.6,1C7.1,57.5,7.4,57.6,7.5,57.7" />{' '}
              <path d="M3.3,51.3C3.5,51.1,3.7,51,4,51c0.3,0,0.5,0.1,0.7,0.3c0.2,0.2,0.3,0.5,0.3,0.9c0,0,0,0,0,0.1 c0,0,0,0.1,0,0.1l-1.8,0.1c0-0.2-0.1-0.4-0.1-0.5C3.1,51.7,3.2,51.5,3.3,51.3 M3.2,53.1C3.2,53.1,3.4,53,3.7,53l2.7-0.1 c0.2,0,0.4,0,0.5,0C7,53.1,7,53.2,7,53.4l0,0.2l0.1,0l-0.1-1.8l-0.1,0l0,0.2c0,0.2,0,0.3-0.1,0.4c-0.1,0.1-0.2,0.1-0.5,0.1l-1.2,0 c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0-0.1,0-0.2,0-0.3L7,50.5l0-1.1l-0.1,0c0,0.2-0.1,0.4-0.2,0.6c-0.1,0.1-0.3,0.3-0.5,0.5L5,51.3 c-0.1-0.4-0.2-0.6-0.4-0.8c-0.2-0.2-0.4-0.2-0.7-0.2c-0.2,0-0.4,0.1-0.6,0.2C3.1,50.7,3,50.9,3,51.1c-0.1,0.2-0.1,0.5-0.1,1 l0.1,1.5l0.1,0l0-0.2C3,53.3,3.1,53.2,3.2,53.1" />{' '}
              <path d="M3,49.4c0.1-0.2,0.1-0.2,0.2-0.3c0.1,0,0.2-0.1,0.4-0.3l1.8-1.1l1,0c0.2,0,0.4,0,0.5,0.1 C6.9,48,7,48.1,7,48.3l0,0.1l0.1,0l0.1-1.8l-0.1,0l0,0.1C7,47,7,47.1,6.9,47.2c-0.1,0.1-0.2,0.1-0.5,0.1l-1.1,0l-1.6-1.1 c-0.2-0.1-0.3-0.2-0.4-0.3c-0.1-0.1-0.1-0.2-0.2-0.3c0-0.1-0.1-0.2-0.1-0.2l0-0.1l-0.1,0L3,46.6l0.1,0c0-0.1,0-0.2,0.1-0.2 c0-0.1,0.1-0.1,0.1-0.1c0,0,0.1,0,0.2,0c0.1,0,0.2,0.1,0.4,0.2l1.3,0.9l-1.4,0.8c-0.2,0.1-0.3,0.2-0.4,0.2c-0.1,0-0.2,0-0.2-0.1 C3,48.1,3,48,3,47.9l0-0.1l-0.1,0l-0.1,1.8l0.1,0C2.9,49.5,3,49.5,3,49.4" />{' '}
              <path d="M4.4,42.9c0.3,0.3,0.7,0.4,1.1,0.5c0.5,0.1,1,0,1.4-0.2c0.6-0.3,0.9-0.8,1.1-1.4c0.1-0.4,0-0.7-0.1-1 c-0.1-0.3-0.3-0.6-0.7-0.9L7.1,40c0.3,0.3,0.5,0.6,0.6,0.8c0.1,0.2,0.1,0.5,0,0.7c-0.1,0.3-0.2,0.6-0.4,0.8 c-0.2,0.2-0.4,0.4-0.7,0.4c-0.3,0.1-0.6,0.1-1,0c-0.5-0.1-0.8-0.2-1.1-0.4c-0.3-0.2-0.5-0.4-0.6-0.6c-0.1-0.2-0.1-0.5-0.1-0.8 c0.1-0.3,0.2-0.6,0.4-0.8c0.2-0.2,0.6-0.3,1-0.4l0-0.1l-1.4-0.2l0,0.1c0.1,0,0.2,0.1,0.2,0.2c0,0,0,0.1,0,0.2c0,0,0,0.1-0.1,0.2 c-0.2,0.3-0.3,0.5-0.4,0.8c-0.1,0.4,0,0.8,0.1,1.1C3.8,42.4,4.1,42.7,4.4,42.9" />{' '}
              <path d="M4.3,38.6c0-0.2,0.1-0.3,0.2-0.3c0.1,0,0.2,0,0.5,0.1l2.6,0.7c0.2,0.1,0.4,0.1,0.4,0.2 c0.1,0.1,0.1,0.2,0.1,0.4l0,0.2l0.1,0l0.9-3.1l-1-0.7l0,0.1c0.2,0.2,0.4,0.3,0.5,0.5c0.1,0.1,0.1,0.3,0.1,0.4c0,0.1,0,0.4-0.1,0.7 L8.5,38c-0.1,0.3-0.1,0.4-0.2,0.5c0,0-0.1,0.1-0.1,0.1c-0.1,0-0.2,0-0.4-0.1l-2.5-0.7c-0.2-0.1-0.4-0.1-0.4-0.2 c-0.1,0-0.1-0.1-0.1-0.2c0-0.1,0-0.3,0.1-0.5l-0.1,0l-0.5,1.8l0.1,0L4.3,38.6z" />{' '}
              <path d="M5.5,34.6c0.1-0.1,0.1-0.2,0.2-0.3c0.1,0,0.3,0,0.5,0.1L7.8,35c0.5,0.2,0.8,0.3,1,0.3c0.3,0,0.5-0.1,0.8-0.2 c0.2-0.2,0.4-0.5,0.6-0.9c0.2-0.4,0.2-0.7,0.1-1c-0.1-0.3-0.2-0.5-0.4-0.7c-0.2-0.2-0.5-0.3-0.9-0.5l-1.6-0.6 c-0.2-0.1-0.3-0.2-0.4-0.2C7,31.1,7,30.9,7,30.8l0.1-0.1l-0.1,0l-0.5,1.4l0.1,0l0.1-0.1c0.1-0.1,0.1-0.2,0.3-0.3 c0.1,0,0.2,0,0.5,0.1l1.6,0.6c0.4,0.2,0.7,0.3,0.8,0.5c0.1,0.1,0.2,0.3,0.3,0.6c0,0.2,0,0.5-0.1,0.8c-0.1,0.2-0.2,0.4-0.3,0.5 c-0.1,0.1-0.3,0.2-0.4,0.2c-0.1,0-0.3,0-0.5,0c-0.2-0.1-0.4-0.1-0.5-0.2l-1.7-0.7c-0.2-0.1-0.4-0.2-0.4-0.2C6,33.5,6,33.4,6,33.2 l0.1-0.2L6,33l-0.7,1.7l0.1,0L5.5,34.6z" />{' '}
              <path d="M8.2,28.6c0.2-0.3,0.4-0.6,0.6-0.7c0.2-0.1,0.5-0.1,0.7,0c0.1,0.1,0.3,0.2,0.3,0.3c0.1,0.1,0.1,0.3,0.1,0.4 c0,0.2-0.1,0.4-0.2,0.7c-0.1,0.1-0.1,0.2-0.2,0.3c-0.1,0.1-0.1,0.2-0.2,0.2L8,29.2C8,29,8.1,28.8,8.2,28.6 M10.4,28.9 c0.2-0.1,0.3-0.2,0.5-0.2c0.2,0,0.3,0,0.5,0.1c0.2,0.1,0.4,0.3,0.4,0.5c0.1,0.3,0,0.5-0.2,0.9c-0.1,0.2-0.2,0.4-0.4,0.6L9.7,30 c0-0.1,0-0.1,0.1-0.2c0-0.1,0.1-0.2,0.1-0.3C10,29.3,10.2,29.1,10.4,28.9 M7.4,30c0.1-0.1,0.2-0.2,0.3-0.3c0.1,0,0.2,0,0.5,0.1 l2.4,1.3c0.2,0.1,0.3,0.2,0.4,0.3c0.1,0.1,0.1,0.3,0,0.4L10.8,32l0.1,0.1l0.9-1.8c0.2-0.4,0.3-0.8,0.4-1c0-0.2,0-0.5-0.1-0.7 c-0.1-0.2-0.3-0.4-0.4-0.5c-0.2-0.1-0.5-0.1-0.8-0.1c-0.2,0.1-0.4,0.2-0.6,0.4c0-0.3,0-0.5-0.1-0.7c-0.1-0.2-0.2-0.3-0.4-0.4 c-0.2-0.1-0.4-0.1-0.7-0.1c-0.2,0.1-0.5,0.2-0.7,0.4C8.3,28,8.2,28.2,8,28.5l-0.8,1.6l0.1,0.1L7.4,30z" />{' '}
              <path d="M90.8,26.9c0.1,0.1,0.2,0.2,0.2,0.3c0.1,0.2,0.1,0.3,0.1,0.5c0,0.2-0.1,0.3-0.2,0.4c-0.1,0.1-0.2,0.3-0.4,0.3 c-0.2,0.1-0.5,0.2-0.7,0.1c-0.2-0.1-0.4-0.2-0.5-0.4c0-0.1-0.1-0.1-0.1-0.2c0-0.1-0.1-0.2-0.1-0.3L90.8,26.9z M87.7,29.2l-0.1-0.1 c-0.1-0.1-0.1-0.3-0.1-0.4c0-0.1,0.2-0.2,0.4-0.3l1.1-0.6c0,0.1,0.1,0.3,0.1,0.4c0,0.1,0.1,0.2,0.1,0.3c0.2,0.4,0.5,0.6,0.7,0.7 c0.3,0.1,0.6,0.1,0.9-0.1c0.2-0.1,0.4-0.3,0.5-0.5c0.1-0.2,0.1-0.4,0.1-0.7c0-0.2-0.1-0.5-0.3-0.8l-0.7-1.3l-0.1,0.1l0.1,0.1 c0.1,0.1,0.1,0.3,0.1,0.4c0,0.1-0.2,0.2-0.4,0.3l-2.4,1.3c-0.2,0.1-0.4,0.2-0.4,0.2c-0.1,0-0.3-0.1-0.3-0.2l-0.1-0.1l-0.1,0.1 l0.9,1.6L87.7,29.2z" />{' '}
              <path d="M92.5,30.2c0.1,0.2,0.2,0.3,0.3,0.4c0.1,0.2,0.1,0.5,0,0.7c-0.1,0.2-0.2,0.4-0.5,0.5c-0.2,0.1-0.5,0.1-0.7,0 c-0.2-0.1-0.4-0.4-0.6-0.7c0,0,0,0,0-0.1c0,0,0-0.1,0-0.1L92.5,30.2z M91.9,29.9L89.4,31c-0.2,0.1-0.4,0.1-0.4,0.1 c-0.1,0-0.2-0.1-0.3-0.3l-0.1-0.1l-0.1,0l0.7,1.6l0.1,0l-0.1-0.2c-0.1-0.1-0.1-0.3,0-0.4c0-0.1,0.2-0.2,0.4-0.3l1.1-0.5 c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0.1,0.1,0.1,0.1,0.2l-1.2,2.1l0.5,1l0.1,0c-0.1-0.2-0.1-0.4-0.1-0.6c0-0.2,0.1-0.4,0.3-0.7 l0.8-1.3c0.2,0.3,0.4,0.5,0.7,0.6c0.2,0.1,0.5,0.1,0.7,0c0.2-0.1,0.4-0.3,0.5-0.5c0.1-0.2,0.1-0.4,0.1-0.6c0-0.2-0.1-0.5-0.3-0.9 L92.3,29l-0.1,0l0.1,0.1c0.1,0.1,0.1,0.3,0,0.4C92.2,29.7,92.1,29.8,91.9,29.9" />{' '}
              <path d="M90.6,35c-0.1,0-0.2-0.1-0.3-0.3l-0.1-0.1l-0.1,0l0.6,1.7l0.1,0l-0.1-0.1c0-0.1,0-0.2,0-0.3 c0-0.1,0-0.1,0.1-0.2c0.1,0,0.2-0.1,0.4-0.2l2.6-0.9c0.2-0.1,0.4-0.1,0.5-0.1c0.1,0,0.2,0.1,0.3,0.3l0.1,0.1l0.1,0l-0.6-1.7 l-0.1,0l0.1,0.1c0,0.1,0,0.2,0,0.3c0,0.1,0,0.1-0.1,0.2c-0.1,0-0.2,0.1-0.4,0.2L91,34.9C90.8,35,90.6,35,90.6,35" />{' '}
              <path d="M94.8,36c-0.1,0.1-0.2,0.1-0.4,0.2l-2.7,0.7c-0.2,0.1-0.4,0.1-0.5,0c-0.1,0-0.2-0.2-0.3-0.3l0-0.1l-0.1,0 l0.4,1.4l0.1,0l0-0.1c0-0.2,0-0.3,0-0.4c0-0.1,0.2-0.1,0.4-0.2l2.7-0.7l-3,2.5l0,0.1l3.8,0.7l-2.7,0.7c-0.2,0.1-0.4,0.1-0.5,0 c-0.1,0-0.2-0.2-0.3-0.3l0-0.1l-0.1,0l0.5,1.7l0.1,0l0-0.1c0-0.2,0-0.3,0-0.4c0-0.1,0.2-0.1,0.4-0.2l2.7-0.7 c0.2-0.1,0.4-0.1,0.5,0c0.1,0,0.2,0.2,0.3,0.3l0,0.1l0.1,0L96,39.5l-3.6-0.6l2.8-2.3l-0.3-1.2l-0.1,0c0,0.2,0.1,0.3,0,0.4 C94.8,35.9,94.8,35.9,94.8,36" />{' '}
              <path d="M95.8,43.3l-1.6,0.9L94,42.8L95.8,43.3z M92.5,42.1c-0.1,0-0.1-0.2-0.2-0.3l-0.1,0l0.2,1.3l0.1,0 c0-0.2,0-0.3,0-0.4c0-0.1,0.1-0.1,0.2-0.1c0.1,0,0.2,0,0.4,0.1l0.7,0.2l0.2,1.6l-0.6,0.3c-0.2,0.1-0.3,0.2-0.4,0.2 c-0.1,0-0.1,0-0.2-0.1c-0.1-0.1-0.1-0.2-0.1-0.3l-0.1,0l0.2,1.6l0.1,0c0-0.2,0-0.3,0.1-0.4c0.1-0.1,0.2-0.2,0.5-0.4l3.3-1.9l0-0.1 l-3.6-1C92.8,42.2,92.6,42.1,92.5,42.1" />{' '}
              <path d="M96.7,48.4c-0.2,0.2-0.4,0.3-0.6,0.3c-0.3,0-0.5-0.1-0.7-0.3c-0.2-0.2-0.3-0.5-0.3-0.9c0,0,0,0,0-0.1 c0,0,0-0.1,0-0.1l1.8-0.1c0,0.2,0.1,0.4,0.1,0.5C96.9,48.1,96.8,48.3,96.7,48.4 M96.3,46.7l-2.7,0.1c-0.2,0-0.4,0-0.5,0 C93,46.7,93,46.6,93,46.4l0-0.2l-0.1,0l0.1,1.8l0.1,0l0-0.2c0-0.2,0-0.3,0.1-0.4c0.1-0.1,0.2-0.1,0.5-0.1l1.2,0c0,0,0,0.1,0,0.1 c0,0,0,0.1,0,0.1c0,0.1,0,0.2,0,0.3L93,49.3l0,1.1l0.1,0c0-0.2,0.1-0.4,0.2-0.6c0.1-0.1,0.3-0.3,0.5-0.5l1.2-0.9 c0.1,0.4,0.2,0.6,0.4,0.8c0.2,0.2,0.4,0.2,0.7,0.2c0.2,0,0.4-0.1,0.6-0.2c0.2-0.2,0.3-0.3,0.3-0.6c0.1-0.2,0.1-0.5,0.1-1L97,46.1 l-0.1,0l0,0.2c0,0.2,0,0.3-0.1,0.4C96.8,46.7,96.6,46.7,96.3,46.7" />{' '}
              <path d="M97.1,52l0.1-1.8l-0.1,0c0,0.1,0,0.1,0,0.2c-0.1,0.2-0.1,0.2-0.2,0.3c-0.1,0-0.2,0.1-0.4,0.3L94.6,52l-1,0 c-0.2,0-0.4,0-0.5-0.1c-0.1-0.1-0.2-0.2-0.1-0.4l0-0.1l-0.1,0l-0.1,1.8l0.1,0l0-0.1c0-0.2,0.1-0.3,0.1-0.4 c0.1-0.1,0.2-0.1,0.5-0.1l1.1,0l1.6,1.1c0.2,0.1,0.3,0.2,0.4,0.3c0.1,0.1,0.2,0.2,0.2,0.3c0,0.1,0.1,0.2,0.1,0.2l0,0.1l0.1,0 l0.1-1.5l-0.1,0c0,0.1,0,0.2,0,0.2c0,0.1-0.1,0.1-0.1,0.1c0,0-0.1,0-0.2,0c-0.1,0-0.2-0.1-0.4-0.2l-1.3-0.9l1.4-0.8 c0.2-0.1,0.3-0.2,0.4-0.2c0.1,0,0.2,0,0.2,0.1c0,0.1,0.1,0.2,0.1,0.3l0,0.1L97.1,52z" />{' '}
              <path d="M95.7,56.8c-0.3-0.2-0.7-0.4-1.1-0.5c-0.5-0.1-1,0-1.4,0.2c-0.6,0.3-0.9,0.8-1.1,1.5c-0.1,0.4,0,0.7,0.1,1 c0.1,0.3,0.3,0.6,0.7,0.9l0.1-0.1c-0.3-0.3-0.5-0.6-0.6-0.8c-0.1-0.2-0.1-0.5,0-0.7c0.1-0.3,0.2-0.6,0.4-0.8 c0.2-0.2,0.4-0.4,0.7-0.4c0.3-0.1,0.6-0.1,1,0c0.5,0.1,0.8,0.2,1.1,0.4c0.3,0.2,0.5,0.4,0.6,0.6c0.1,0.2,0.1,0.5,0.1,0.8 c-0.1,0.3-0.2,0.6-0.4,0.8c-0.2,0.2-0.6,0.3-1,0.4l0,0.1l1.4,0.2l0-0.1c-0.1,0-0.2-0.1-0.2-0.2c0,0,0-0.1,0-0.2c0,0,0-0.1,0.1-0.2 c0.2-0.3,0.3-0.5,0.4-0.8c0.1-0.4,0-0.8-0.1-1.1C96.2,57.4,96,57.1,95.7,56.8" />{' '}
              <path d="M95.7,61.2c0,0.2-0.1,0.3-0.2,0.3c-0.1,0-0.2,0-0.5,0l-2.6-0.7c-0.2-0.1-0.4-0.1-0.4-0.2 c-0.1-0.1-0.1-0.2-0.1-0.4l0-0.2l-0.1,0L91,63.1l1,0.7l0-0.1c-0.2-0.2-0.4-0.3-0.5-0.5c-0.1-0.1-0.1-0.3-0.1-0.4 c0-0.1,0-0.4,0.1-0.7l0.1-0.3c0.1-0.3,0.1-0.4,0.2-0.5c0,0,0.1-0.1,0.1-0.1c0.1,0,0.2,0,0.4,0l2.5,0.7c0.2,0.1,0.4,0.1,0.4,0.2 c0.1,0,0.1,0.1,0.1,0.2c0,0.1,0,0.3-0.1,0.5l0.1,0l0.5-1.8l-0.1,0L95.7,61.2z" />{' '}
              <path d="M94.6,65.1c-0.1,0.1-0.1,0.2-0.2,0.3c-0.1,0-0.3,0-0.5-0.1l-1.5-0.6c-0.5-0.2-0.8-0.3-1-0.3 c-0.3,0-0.5,0.1-0.8,0.2c-0.2,0.2-0.4,0.5-0.6,0.9c-0.2,0.4-0.2,0.7-0.1,1c0.1,0.3,0.2,0.5,0.4,0.7c0.2,0.2,0.5,0.3,0.9,0.5 l1.6,0.6c0.2,0.1,0.3,0.2,0.4,0.2c0.1,0.1,0.1,0.3,0.1,0.4L93,69.2l0.1,0l0.5-1.4l-0.1,0l-0.1,0.1c-0.1,0.1-0.1,0.2-0.3,0.3 c-0.1,0-0.2,0-0.5-0.1l-1.6-0.6c-0.4-0.2-0.7-0.3-0.8-0.5c-0.1-0.1-0.2-0.3-0.3-0.6c0-0.2,0-0.5,0.1-0.8c0.1-0.2,0.2-0.4,0.3-0.5 c0.1-0.1,0.3-0.2,0.4-0.2c0.1,0,0.3,0,0.5,0c0.2,0,0.4,0.1,0.5,0.2l1.7,0.7C93.9,66,94,66,94,66.1c0.1,0.1,0.1,0.2,0,0.4L94,66.7 l0.1,0l0.7-1.7l-0.1,0L94.6,65.1z" />{' '}
              <path d="M91.9,71.1c-0.2,0.3-0.4,0.6-0.6,0.7c-0.2,0.1-0.5,0.1-0.7,0c-0.1-0.1-0.3-0.2-0.3-0.3 c-0.1-0.1-0.1-0.3-0.1-0.4c0-0.2,0.1-0.4,0.2-0.7c0.1-0.1,0.1-0.2,0.2-0.3c0.1-0.1,0.1-0.2,0.1-0.2l1.4,0.7 C92.1,70.8,92,70.9,91.9,71.1 M89.8,70.8c-0.2,0.1-0.3,0.2-0.5,0.2c-0.2,0-0.3,0-0.5-0.1c-0.2-0.1-0.4-0.3-0.4-0.5 c-0.1-0.3,0-0.5,0.2-0.9c0.1-0.2,0.2-0.4,0.4-0.6l1.5,0.8c0,0.1,0,0.1-0.1,0.2c0,0.1-0.1,0.2-0.1,0.3 C90.1,70.5,89.9,70.7,89.8,70.8 M92.8,69.7c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0-0.2,0-0.5-0.1l-2.4-1.2c-0.2-0.1-0.3-0.2-0.4-0.3 c-0.1-0.1-0.1-0.3,0-0.4l0.1-0.1l-0.1-0.1l-0.9,1.8c-0.2,0.4-0.3,0.8-0.4,1c0,0.2,0,0.5,0.1,0.7c0.1,0.2,0.3,0.4,0.4,0.5 c0.2,0.1,0.5,0.1,0.8,0c0.2-0.1,0.4-0.2,0.6-0.4c0,0.3,0,0.5,0.1,0.7c0.1,0.2,0.2,0.3,0.4,0.4c0.2,0.1,0.5,0.1,0.7,0.1 c0.2-0.1,0.5-0.2,0.7-0.5c0.1-0.2,0.3-0.4,0.4-0.7l0.8-1.6l-0.1-0.1L92.8,69.7z" />{' '}
              <path d="M85.3,85.3C75.8,94.7,63.3,99.9,50,99.9c-13.3,0-25.8-5.2-35.3-14.6C5.3,75.8,0.1,63.3,0.1,50 c0-13.3,5.2-25.8,14.6-35.3C24.2,5.3,36.7,0.1,50,0.1c13.3,0,25.8,5.2,35.3,14.6c9.4,9.4,14.6,21.9,14.6,35.3 C99.9,63.3,94.7,75.8,85.3,85.3 M85.4,14.6C75.9,5.2,63.4,0,50,0C36.6,0,24.1,5.2,14.6,14.6C5.2,24.1,0,36.6,0,50 c0,13.4,5.2,25.9,14.6,35.4c9.4,9.4,22,14.6,35.4,14.6c13.4,0,25.9-5.2,35.4-14.6c9.4-9.4,14.6-22,14.6-35.4 C100,36.6,94.8,24.1,85.4,14.6" />{' '}
              <path d="M42,17.1c-0.2,0.2-0.3,0.3-0.6,0.3c-0.1,0-0.1,0-0.2,0c-0.1,0-0.2,0-0.3,0v-1.8c0.2,0,0.3-0.1,0.4-0.1 c0.2,0,0.3,0,0.5,0.1c0.1,0.1,0.2,0.2,0.3,0.4c0.1,0.2,0.1,0.3,0.1,0.5C42.3,16.7,42.2,16.9,42,17.1 M42.2,15.3 c-0.2-0.1-0.5-0.1-0.9-0.1h-1.5v0.1h0.2c0.2,0,0.3,0,0.4,0.1c0.1,0.1,0.1,0.2,0.1,0.5v2.7c0,0.2,0,0.4-0.1,0.5 c-0.1,0.1-0.2,0.2-0.4,0.2h-0.2v0.1h1.8v-0.1h-0.2c-0.2,0-0.3,0-0.4-0.1C41,19.1,41,19,41,18.7v-1.2c0.1,0,0.3,0.1,0.4,0.1 c0.1,0,0.2,0,0.3,0c0.4,0,0.7-0.1,1-0.3c0.2-0.2,0.3-0.5,0.3-0.8c0-0.3-0.1-0.5-0.2-0.7C42.6,15.6,42.4,15.4,42.2,15.3" />{' '}
              <path d="M44.7,17.3C44.7,17.3,44.6,17.3,44.7,17.3c-0.1,0-0.1,0-0.2,0v-1.8c0.2,0,0.4-0.1,0.5-0.1 c0.3,0,0.5,0.1,0.7,0.2c0.2,0.2,0.2,0.4,0.2,0.6c0,0.3-0.1,0.5-0.3,0.7C45.4,17.2,45.1,17.3,44.7,17.3 M46.4,18.6l-0.9-1.2 c0.4-0.1,0.6-0.2,0.8-0.4c0.2-0.2,0.3-0.4,0.3-0.7c0-0.2-0.1-0.5-0.2-0.6c-0.1-0.2-0.3-0.3-0.5-0.4c-0.2-0.1-0.5-0.1-1-0.1h-1.5 v0.1h0.2c0.2,0,0.3,0,0.4,0.1c0.1,0.1,0.1,0.2,0.1,0.5v2.7c0,0.2,0,0.4-0.1,0.5c-0.1,0.1-0.2,0.2-0.4,0.2h-0.2v0.1h1.8v-0.1H45 c-0.2,0-0.3,0-0.4-0.1c-0.1-0.1-0.1-0.2-0.1-0.5v-1.2c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0.1,0,0.2,0,0.3,0l1.4,2h1.1v-0.1 c-0.2,0-0.4-0.1-0.6-0.2C46.8,19.1,46.6,18.9,46.4,18.6" />{' '}
              <path d="M50.3,18.5l-1.5-3.3h-1.2v0.1c0.2,0,0.3,0,0.4,0.1c0.1,0,0.1,0.1,0.2,0.2c0,0.1,0.1,0.2,0.1,0.4v2.8 c0,0.2,0,0.4-0.1,0.5c-0.1,0.1-0.2,0.2-0.4,0.2h-0.1v0.1H49v-0.1h-0.1c-0.2,0-0.3,0-0.4-0.1c-0.1-0.1-0.1-0.2-0.1-0.5v-2.8 l1.6,3.5h0.1l1.6-3.5v2.8c0,0.2,0,0.4-0.1,0.5c-0.1,0.1-0.2,0.2-0.4,0.2h-0.1v0.1H53v-0.1h-0.1c-0.2,0-0.3,0-0.4-0.1 c-0.1-0.1-0.1-0.2-0.1-0.5V16c0-0.2,0-0.4,0.1-0.5c0.1-0.1,0.2-0.2,0.4-0.2H53v-0.1h-1.2L50.3,18.5z" />{' '}
              <path d="M54.6,17.3C54.6,17.3,54.5,17.3,54.6,17.3c-0.1,0-0.1,0-0.2,0v-1.8c0.2,0,0.4-0.1,0.5-0.1 c0.3,0,0.5,0.1,0.7,0.2c0.2,0.2,0.2,0.4,0.2,0.6c0,0.3-0.1,0.5-0.3,0.7C55.3,17.2,55,17.3,54.6,17.3 M56.3,18.6l-0.9-1.2 c0.4-0.1,0.6-0.2,0.8-0.4c0.2-0.2,0.3-0.4,0.3-0.7c0-0.2-0.1-0.5-0.2-0.6s-0.3-0.3-0.5-0.4c-0.2-0.1-0.5-0.1-1-0.1h-1.5v0.1h0.2 c0.2,0,0.3,0,0.4,0.1c0.1,0.1,0.1,0.2,0.1,0.5v2.7c0,0.2,0,0.4-0.1,0.5c-0.1,0.1-0.2,0.2-0.4,0.2h-0.2v0.1H55v-0.1h-0.2 c-0.2,0-0.3,0-0.4-0.1c-0.1-0.1-0.1-0.2-0.1-0.5v-1.2c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0.1,0,0.2,0,0.3,0l1.4,2h1.1v-0.1 c-0.2,0-0.4-0.1-0.6-0.2C56.7,19.1,56.5,18.9,56.3,18.6" />{' '}
              <path d="M60,15.2v0.1c0.1,0,0.2,0,0.3,0c0.1,0,0.1,0.1,0.1,0.1c0,0,0,0.1,0,0.2c0,0.1-0.1,0.2-0.2,0.4l-0.8,1.3 L58.6,16c-0.1-0.2-0.2-0.3-0.2-0.4c0-0.1,0-0.2,0.1-0.2c0.1,0,0.2-0.1,0.3-0.1h0.1v-0.1H57v0.1c0.1,0,0.1,0,0.2,0 c0.2,0.1,0.3,0.1,0.3,0.2c0,0.1,0.1,0.2,0.3,0.4l1.2,1.8v1c0,0.2,0,0.4-0.1,0.5c-0.1,0.1-0.2,0.2-0.4,0.2h-0.1v0.1h1.8v-0.1H60 c-0.2,0-0.3,0-0.4-0.1c-0.1-0.1-0.1-0.2-0.1-0.5v-1.1l1-1.6c0.1-0.2,0.2-0.3,0.3-0.4c0.1-0.1,0.2-0.2,0.3-0.2 c0.1,0,0.2-0.1,0.2-0.1h0.1v-0.1H60z" />{' '}
              <path d="M24.6,23.9C24.6,23.9,24.6,23.9,24.6,23.9C24.6,24,24.5,24,24.5,24c0,0,0,0.1,0,0.1c0,0,0.1,0,0.1,0 c0,0,0.1,0,0.1-0.1c0-0.1,0.1-0.1,0.1-0.2c0-0.1,0-0.1-0.1-0.2c-0.1-0.1-0.2-0.1-0.3-0.1c-0.2,0-0.3,0.1-0.5,0.2 c-0.2,0.1-0.3,0.3-0.4,0.4c-0.1,0.2-0.2,0.4-0.2,0.5c0,0.2,0,0.3,0.1,0.4c0.1,0.1,0.2,0.1,0.4,0.1c0.1,0,0.3,0,0.4-0.1 c0.1-0.1,0.3-0.2,0.4-0.3l-0.1,0c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0-0.2,0-0.3-0.1c-0.1-0.1-0.1-0.2-0.1-0.3 c0-0.2,0-0.4,0.1-0.6c0.1-0.2,0.2-0.3,0.3-0.4c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.1,0,0.1,0C24.7,23.8,24.7,23.8,24.6,23.9 C24.7,23.8,24.7,23.8,24.6,23.9C24.7,23.8,24.6,23.9,24.6,23.9" />{' '}
              <path d="M76.7,76.4c-0.1,0.2-0.2,0.3-0.3,0.4c-0.1,0.1-0.2,0.1-0.3,0.1c-0.1,0-0.2,0-0.3-0.1L76,76 c0-0.2,0.1-0.3,0.2-0.4c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.1,0,0.2,0.1c0.1,0.1,0.1,0.1,0.1,0.3C76.8,76,76.8,76.2,76.7,76.4 M76.7,75.3c-0.1,0-0.2,0-0.3,0.1c-0.1,0-0.2,0.1-0.3,0.2l0.4-1.2l-0.6,0.1v0.1c0.1,0,0.1,0,0.1,0c0.1,0,0.1,0,0.1,0 c0,0,0,0,0,0.1c0,0,0,0.1-0.1,0.3l-0.5,1.9c0.2,0.1,0.4,0.2,0.6,0.2c0.2,0,0.3-0.1,0.5-0.2c0.2-0.1,0.3-0.3,0.4-0.4 c0.1-0.2,0.2-0.4,0.2-0.6c0-0.1,0-0.3-0.1-0.4C76.9,75.3,76.8,75.3,76.7,75.3" />{' '}
              <path d="M75.6,24.9c0,0.1-0.1,0.2-0.1,0.3c0,0,0,0.1,0,0.1c0,0,0.1,0,0.1,0c0.1,0,0.1,0,0.2-0.1 c0.1-0.1,0.2-0.2,0.3-0.3l-0.1-0.1C76.1,24.9,76,25,76,25.1c0,0-0.1,0-0.1,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0-0.1,0-0.2l0.6-2.1 l-0.6,0.1v0.1c0.1,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0,0,0,0.1c0,0,0,0.1-0.1,0.2L75.6,24.9z" />{' '}
              <path d="M24.8,76.7C24.7,76.7,24.7,76.8,24.8,76.7C24.7,76.8,24.7,76.8,24.8,76.7c-0.1,0-0.1,0-0.1,0 c0,0,0-0.1,0.1-0.3l0.3-1.1h-0.3l0,0.1c-0.1,0.3-0.2,0.5-0.2,0.6c-0.1,0.1-0.2,0.2-0.3,0.4c-0.1,0.1-0.2,0.2-0.3,0.3 c-0.1,0-0.1,0.1-0.2,0.1c0,0,0,0-0.1,0c0,0,0,0,0-0.1c0,0,0-0.1,0.1-0.3l0.2-0.6c0-0.2,0.1-0.3,0.1-0.3c0,0,0-0.1,0-0.1 c0,0-0.1,0-0.1,0c-0.1,0-0.1,0-0.2,0.1c-0.1,0.1-0.2,0.2-0.3,0.3l0.1,0c0.1-0.1,0.1-0.2,0.2-0.2c0,0,0.1,0,0.1,0c0,0,0,0,0,0 c0,0,0,0,0,0c0,0,0,0.1-0.1,0.2l-0.2,0.6c-0.1,0.2-0.1,0.3-0.1,0.4c0,0.1,0,0.1,0.1,0.1c0,0,0.1,0.1,0.1,0.1 c0.1,0,0.2-0.1,0.4-0.2c0.1-0.1,0.3-0.4,0.5-0.7c-0.1,0.3-0.1,0.5-0.2,0.6c0,0.1,0,0.1,0,0.2c0,0,0,0.1,0,0.1c0,0,0.1,0,0.1,0 c0.1,0,0.1,0,0.2-0.1c0.1-0.1,0.2-0.2,0.3-0.3l-0.1,0C24.9,76.6,24.8,76.7,24.8,76.7" />{' '}
              <path d="M17.5,61.1c0,0.1,0,0.2,0,0.3h-1.8c0-0.2-0.1-0.3-0.1-0.4c0-0.2,0-0.3,0.1-0.5c0.1-0.1,0.2-0.2,0.4-0.3 c0.2-0.1,0.3-0.1,0.5-0.1c0.3,0,0.5,0.1,0.6,0.2c0.2,0.2,0.2,0.3,0.2,0.6C17.5,60.9,17.5,61,17.5,61.1 M19.5,60.9 c0,0.2,0,0.3-0.1,0.4c-0.1,0.1-0.2,0.1-0.5,0.1h-1.2c0-0.1,0.1-0.3,0.1-0.4c0-0.1,0-0.2,0-0.3c0-0.4-0.1-0.7-0.3-1 c-0.2-0.2-0.5-0.3-0.8-0.3c-0.3,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.3,0.3-0.4,0.5c-0.1,0.2-0.1,0.5-0.1,0.9v1.5h0.1v-0.2 c0-0.2,0-0.3,0.1-0.4c0.1-0.1,0.2-0.1,0.5-0.1h2.7c0.2,0,0.4,0,0.5,0.1c0.1,0.1,0.2,0.2,0.2,0.4v0.2h0.1v-1.8h-0.1V60.9z" />{' '}
              <path d="M19.4,57.9c0,0.1-0.1,0.1-0.1,0.1c-0.1,0-0.2,0.1-0.4,0.1h-1.4c-0.2-0.1-0.3-0.2-0.4-0.3c0,0-0.1-0.1-0.1-0.1 c0,0,0-0.1,0.1-0.2c0.1-0.1,0.1-0.2,0.1-0.2c0-0.1,0-0.1-0.1-0.2C17.2,57,17.1,57,17,57c-0.1,0-0.2,0-0.2,0.1 c-0.1,0.1-0.1,0.2-0.1,0.3c0,0.3,0.2,0.5,0.6,0.7h-0.6v0.1l0.3,0.9l0.1,0c0-0.1,0-0.2,0-0.2c0-0.1,0-0.1,0-0.1 c0,0,0.1-0.1,0.1-0.1c0.1,0,0.3,0,0.6,0H19c0.2,0,0.3,0,0.3,0c0.1,0,0.1,0.1,0.2,0.1c0,0.1,0.1,0.2,0.1,0.3h0.1v-1.5h-0.1 C19.5,57.7,19.5,57.8,19.4,57.9" />{' '}
            </g>{' '}
            <rect x="15.7" y="55.2" width="0.4" height="2" />{' '}
            <g>
              {' '}
              <path d="M19.5,55.7c0,0.1-0.1,0.1-0.1,0.1c-0.1,0-0.2,0-0.4,0h-2.3V56l0.3,0.9l0.1,0c0-0.1,0-0.2,0-0.2 c0-0.1,0-0.1,0-0.1c0,0,0.1-0.1,0.1-0.1c0.1,0,0.3,0,0.6,0H19c0.2,0,0.3,0,0.4,0c0.1,0,0.1,0.1,0.1,0.1c0,0,0,0.1,0,0.3h0.1v-1.4 h-0.1C19.5,55.5,19.5,55.6,19.5,55.7" />{' '}
            </g>{' '}
            <rect x="18" y="53.4" width="0.5" height="1.6" />{' '}
            <g>
              {' '}
              <path d="M19.5,46.4c0,0.1-0.1,0.1-0.1,0.1c-0.1,0-0.2,0-0.4,0h-1.2c-0.3,0-0.4,0-0.6,0.1c-0.2,0.1-0.3,0.2-0.4,0.3 c-0.1,0.1-0.1,0.3-0.1,0.4c0,0.2,0,0.3,0.1,0.5c0.1,0.1,0.2,0.3,0.5,0.5c-0.2,0-0.3,0.1-0.5,0.3c-0.1,0.1-0.2,0.3-0.2,0.5 c0,0.1,0,0.2,0.1,0.3c0,0.1,0.1,0.2,0.2,0.3c0,0,0.2,0.2,0.4,0.4h-0.6v0.1L17,51l0.1,0c0-0.1,0-0.2,0-0.2c0-0.1,0-0.1,0-0.1 c0,0,0.1-0.1,0.1-0.1c0.1,0,0.3,0,0.6,0H19c0.2,0,0.3,0,0.4,0c0.1,0,0.1,0.1,0.1,0.1c0,0.1,0,0.1,0,0.3h0.1v-1.4h-0.1 c0,0.2,0,0.3,0,0.3c0,0.1-0.1,0.1-0.1,0.1c-0.1,0-0.2,0-0.4,0h-1.5c-0.1-0.1-0.2-0.2-0.3-0.4c-0.1-0.1-0.1-0.2-0.1-0.3 c0-0.2,0.1-0.3,0.2-0.4c0.1-0.1,0.3-0.1,0.5-0.1H19c0.2,0,0.3,0,0.3,0c0.1,0,0.1,0.1,0.2,0.1c0,0.1,0.1,0.2,0.1,0.3h0.1v-1.4h-0.1 c0,0.1,0,0.2,0,0.3c0,0.1-0.1,0.1-0.1,0.1c0,0-0.2,0-0.4,0h-1.4l-0.1,0l0,0c-0.1-0.2-0.3-0.3-0.3-0.4c-0.1-0.1-0.1-0.2-0.1-0.3 c0-0.2,0.1-0.3,0.2-0.4c0.1-0.1,0.3-0.1,0.5-0.1H19c0.2,0,0.3,0,0.3,0c0.1,0,0.1,0.1,0.2,0.1c0,0.1,0.1,0.2,0.1,0.3v0.1h0.1v-1.4 h-0.1C19.5,46.2,19.5,46.3,19.5,46.4" />{' '}
              <path d="M17.7,45.4c-0.2,0-0.4-0.1-0.6-0.2c-0.1-0.1-0.2-0.3-0.2-0.5c0-0.1,0-0.2,0.1-0.3c0.1-0.1,0.2-0.2,0.3-0.2 c0.1,0,0.2-0.1,0.4-0.1V45.4z M18.6,43.4L18.6,43.4c0.2,0.2,0.4,0.3,0.5,0.4c0.1,0.1,0.2,0.3,0.2,0.5c0,0.3-0.1,0.5-0.4,0.7 c-0.2,0.2-0.6,0.3-1,0.3v-2c-0.4,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.5-0.3,0.8c0,0.4,0.1,0.7,0.4,0.9c0.3,0.2,0.7,0.4,1.1,0.4 c0.4,0,0.8-0.1,1.1-0.4c0.3-0.2,0.4-0.5,0.4-0.9c0-0.3-0.1-0.6-0.3-0.8C19.1,43.6,18.9,43.5,18.6,43.4" />{' '}
              <path d="M16.7,42.4l0.3,0.9l0.1,0c0-0.1,0-0.2,0-0.2c0-0.1,0-0.1,0-0.1c0,0,0.1-0.1,0.1-0.1c0.1,0,0.3,0,0.6,0H19 c0.2,0,0.3,0,0.3,0c0.1,0,0.1,0.1,0.2,0.1c0,0.1,0.1,0.2,0.1,0.3h0.1v-1.5h-0.1c0,0.1,0,0.2-0.1,0.3c0,0.1-0.1,0.1-0.1,0.1 c-0.1,0-0.2,0.1-0.4,0.1h-1.4c-0.2-0.1-0.3-0.2-0.4-0.3c0,0-0.1-0.1-0.1-0.1c0,0,0-0.1,0.1-0.2c0.1-0.1,0.1-0.2,0.1-0.2 c0-0.1,0-0.1-0.1-0.2c-0.1-0.1-0.1-0.1-0.2-0.1c-0.1,0-0.2,0-0.2,0.1c-0.1,0.1-0.1,0.2-0.1,0.3c0,0.3,0.2,0.5,0.6,0.7h-0.6V42.4z" />{' '}
            </g>{' '}
            <rect x="18" y="39.4" width="0.5" height="1.6" />{' '}
            <g>
              {' '}
              <path d="M17.7,38.5c-0.2,0-0.4-0.1-0.6-0.2c-0.1-0.1-0.2-0.3-0.2-0.5c0-0.1,0-0.2,0.1-0.3c0.1-0.1,0.2-0.2,0.3-0.2 c0.1,0,0.2-0.1,0.4-0.1V38.5z M18.6,36.5L18.6,36.5c0.2,0.2,0.4,0.3,0.5,0.4c0.1,0.1,0.2,0.3,0.2,0.5c0,0.3-0.1,0.5-0.4,0.7 c-0.2,0.2-0.6,0.3-1,0.3v-2c-0.4,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.5-0.3,0.8c0,0.4,0.1,0.7,0.4,0.9c0.3,0.2,0.7,0.4,1.1,0.4 c0.4,0,0.8-0.1,1.1-0.4c0.3-0.2,0.4-0.5,0.4-0.9c0-0.3-0.1-0.6-0.3-0.8C19.1,36.7,18.9,36.5,18.6,36.5" />{' '}
            </g>{' '}
            <rect x="15.7" y="36.7" width="0.4" height="2" />{' '}
            <g>
              {' '}
              <path d="M84.4,38.5c-0.1,0.1-0.2,0.2-0.4,0.3c-0.2,0.1-0.3,0.1-0.5,0.1c-0.3,0-0.5-0.1-0.6-0.2 c-0.2-0.2-0.3-0.3-0.3-0.6c0-0.1,0-0.1,0-0.2c0-0.1,0-0.2,0-0.3h1.8c0,0.2,0.1,0.3,0.1,0.4C84.5,38.2,84.4,38.4,84.4,38.5 M84.6,36.4v0.2c0,0.2,0,0.3-0.1,0.4C84.4,37,84.3,37,84,37h-2.7c-0.2,0-0.4,0-0.5-0.1c-0.1-0.1-0.2-0.2-0.2-0.4v-0.2h-0.1v1.8 h0.1v-0.2c0-0.2,0-0.3,0.1-0.4c0.1-0.1,0.2-0.1,0.5-0.1h1.2c0,0.1-0.1,0.3-0.1,0.4c0,0.1,0,0.2,0,0.3c0,0.4,0.1,0.7,0.3,1 c0.2,0.2,0.5,0.3,0.8,0.3c0.3,0,0.5-0.1,0.7-0.2c0.2-0.1,0.3-0.3,0.4-0.5c0.1-0.2,0.1-0.5,0.1-0.9v-1.5H84.6z" />{' '}
              <path d="M80.7,41.1c0-0.1,0.1-0.1,0.1-0.1c0.1,0,0.2-0.1,0.4-0.1h1.4c0.2,0.1,0.3,0.2,0.4,0.3c0,0,0.1,0.1,0.1,0.1 c0,0,0,0.1-0.1,0.2c-0.1,0.1-0.1,0.2-0.1,0.2c0,0.1,0,0.1,0.1,0.2C83,42,83,42,83.1,42c0.1,0,0.2,0,0.2-0.1 c0.1-0.1,0.1-0.2,0.1-0.3c0-0.3-0.2-0.5-0.6-0.7h0.6v-0.1l-0.3-0.9l-0.1,0c0,0.1,0,0.2,0,0.2c0,0.1,0,0.1,0,0.1 c0,0-0.1,0.1-0.1,0.1c-0.1,0-0.3,0-0.6,0h-1.1c-0.2,0-0.3,0-0.3,0c-0.1,0-0.1-0.1-0.2-0.1c0-0.1-0.1-0.2-0.1-0.3h-0.1v1.5h0.1 C80.6,41.3,80.7,41.2,80.7,41.1" />{' '}
            </g>{' '}
            <rect x="84" y="41.8" width="0.4" height="2" />{' '}
            <g>
              {' '}
              <path d="M80.7,43.3c0-0.1,0.1-0.1,0.1-0.1c0.1,0,0.2,0,0.4,0h2.3V43l-0.3-0.9l-0.1,0c0,0.1,0,0.2,0,0.2 c0,0.1,0,0.1,0,0.1c0,0-0.1,0.1-0.1,0.1c-0.1,0-0.3,0-0.6,0h-1.1c-0.2,0-0.3,0-0.4,0c-0.1,0-0.1-0.1-0.1-0.1c0,0,0-0.1,0-0.3h-0.1 v1.4h0.1C80.6,43.5,80.6,43.4,80.7,43.3" />{' '}
            </g>{' '}
            <rect x="81.7" y="44" width="0.5" height="1.6" /> <rect x="79.2" y="46.8" width="1" height="0.3" />{' '}
            <g>
              {' '}
              <path d="M80.7,52.6c0-0.1,0.1-0.1,0.1-0.1c0.1,0,0.2,0,0.4,0h1.2c0.3,0,0.4,0,0.6-0.1c0.2-0.1,0.3-0.2,0.4-0.3 c0.1-0.1,0.1-0.3,0.1-0.4c0-0.2,0-0.3-0.1-0.5c-0.1-0.1-0.2-0.3-0.5-0.5c0.2,0,0.3-0.1,0.5-0.3c0.1-0.1,0.2-0.3,0.2-0.5 c0-0.1,0-0.2-0.1-0.3c0-0.1-0.1-0.2-0.2-0.3c0,0-0.2-0.2-0.4-0.4h0.6v-0.1L83.1,48l-0.1,0c0,0.1,0,0.2,0,0.2c0,0.1,0,0.1,0,0.1 c0,0-0.1,0.1-0.1,0.1c-0.1,0-0.3,0-0.6,0h-1.1c-0.2,0-0.3,0-0.4,0c-0.1,0-0.1-0.1-0.1-0.1c0-0.1,0-0.1,0-0.3h-0.1v1.4h0.1 c0-0.2,0-0.3,0-0.3c0-0.1,0.1-0.1,0.1-0.1c0.1,0,0.2,0,0.4,0h1.5c0.1,0.1,0.2,0.2,0.3,0.4c0.1,0.1,0.1,0.2,0.1,0.3 c0,0.2-0.1,0.3-0.2,0.4c-0.1,0.1-0.3,0.1-0.5,0.1h-1.2c-0.2,0-0.3,0-0.3,0c-0.1,0-0.1-0.1-0.2-0.1c0-0.1-0.1-0.2-0.1-0.3h-0.1v1.4 h0.1c0-0.1,0-0.2,0-0.3c0-0.1,0.1-0.1,0.1-0.1c0,0,0.2,0,0.4,0h1.4l0.1,0l0,0c0.1,0.2,0.3,0.3,0.3,0.4c0.1,0.1,0.1,0.2,0.1,0.3 c0,0.2-0.1,0.3-0.2,0.4c-0.1,0.1-0.3,0.1-0.5,0.1h-1.2c-0.2,0-0.3,0-0.3,0c-0.1,0-0.1-0.1-0.2-0.1c0-0.1-0.1-0.2-0.1-0.3v-0.1 h-0.1v1.4h0.1C80.6,52.8,80.6,52.7,80.7,52.6" />{' '}
              <path d="M83.1,54.6c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.4,0.1v-1.3c0.2,0,0.4,0.1,0.6,0.2 c0.1,0.1,0.2,0.3,0.2,0.5C83.2,54.4,83.2,54.5,83.1,54.6 M81.9,53.2c-0.4,0-0.8,0.1-1.1,0.4c-0.3,0.2-0.4,0.5-0.4,0.9 c0,0.3,0.1,0.6,0.3,0.8c0.2,0.2,0.5,0.3,0.8,0.4l0.1-0.1c-0.2-0.1-0.4-0.2-0.5-0.3c-0.1-0.1-0.2-0.3-0.2-0.5 c0-0.3,0.1-0.5,0.4-0.7c0.2-0.2,0.6-0.3,1-0.3v2c0.4,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8c0-0.4-0.1-0.7-0.4-0.9 C82.8,53.3,82.4,53.2,81.9,53.2" />{' '}
              <path d="M82.9,56.2c-0.1,0-0.3,0-0.6,0h-1.1c-0.2,0-0.3,0-0.3,0c-0.1,0-0.1-0.1-0.2-0.1c0-0.1-0.1-0.2-0.1-0.3h-0.1 v1.5h0.1c0-0.1,0-0.2,0.1-0.3c0-0.1,0.1-0.1,0.1-0.1c0.1,0,0.2-0.1,0.4-0.1h1.4c0.2,0.1,0.3,0.2,0.4,0.3c0,0,0.1,0.1,0.1,0.1 c0,0,0,0.1-0.1,0.2c-0.1,0.1-0.1,0.2-0.1,0.2c0,0.1,0,0.1,0.1,0.2c0.1,0.1,0.1,0.1,0.2,0.1c0.1,0,0.2,0,0.2-0.1 c0.1-0.1,0.1-0.2,0.1-0.3c0-0.3-0.2-0.5-0.6-0.7h0.6v-0.1l-0.3-0.9l-0.1,0c0,0.1,0,0.2,0,0.2c0,0.1,0,0.1,0,0.1 C83,56.2,82.9,56.2,82.9,56.2" />{' '}
            </g>{' '}
            <rect x="81.7" y="58" width="0.5" height="1.6" />{' '}
            <g>
              {' '}
              <path d="M83.1,61.5c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.4,0.1v-1.3c0.2,0,0.4,0.1,0.6,0.2 c0.1,0.1,0.2,0.3,0.2,0.5C83.2,61.3,83.2,61.4,83.1,61.5 M81.9,60.1c-0.4,0-0.8,0.1-1.1,0.4c-0.3,0.2-0.4,0.5-0.4,0.9 c0,0.3,0.1,0.6,0.3,0.8c0.2,0.2,0.5,0.3,0.8,0.4l0.1-0.1c-0.2-0.1-0.4-0.2-0.5-0.3c-0.1-0.1-0.2-0.3-0.2-0.5 c0-0.3,0.1-0.5,0.4-0.7c0.2-0.2,0.6-0.3,1-0.3v2c0.4,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8c0-0.4-0.1-0.7-0.4-0.9 C82.8,60.2,82.4,60.1,81.9,60.1" />{' '}
            </g>{' '}
            <rect x="84" y="60.3" width="0.4" height="2" />{' '}
            <g>
              {' '}
              <path d="M59.1,82.8c0.2-0.2,0.3-0.3,0.6-0.3c0.1,0,0.1,0,0.2,0c0.1,0,0.2,0,0.3,0v1.8c-0.2,0-0.3,0.1-0.4,0.1 c-0.2,0-0.3,0-0.5-0.1c-0.1-0.1-0.2-0.2-0.3-0.4c-0.1-0.2-0.1-0.3-0.1-0.5C58.9,83.2,59,83,59.1,82.8 M59,84.6 c0.2,0.1,0.5,0.1,0.9,0.1h1.5v-0.1h-0.2c-0.2,0-0.3,0-0.4-0.1c-0.1-0.1-0.1-0.2-0.1-0.5v-2.7c0-0.2,0-0.4,0.1-0.5 c0.1-0.1,0.2-0.2,0.4-0.2h0.2v-0.1h-1.8v0.1h0.2c0.2,0,0.3,0,0.4,0.1c0.1,0.1,0.1,0.2,0.1,0.5v1.2c-0.1,0-0.3-0.1-0.4-0.1 c-0.1,0-0.2,0-0.3,0c-0.4,0-0.7,0.1-1,0.3c-0.2,0.2-0.3,0.5-0.3,0.8c0,0.3,0.1,0.5,0.2,0.7C58.6,84.4,58.7,84.5,59,84.6" />{' '}
              <path d="M56.5,82.6C56.5,82.6,56.5,82.6,56.5,82.6c0.1,0,0.1,0,0.2,0v1.8c-0.2,0-0.4,0.1-0.5,0.1 c-0.3,0-0.5-0.1-0.7-0.2c-0.2-0.2-0.2-0.4-0.2-0.6c0-0.3,0.1-0.5,0.3-0.7C55.8,82.7,56.1,82.6,56.5,82.6 M54.7,81.3l0.9,1.2 c-0.4,0.1-0.6,0.2-0.8,0.4c-0.2,0.2-0.3,0.4-0.3,0.7c0,0.2,0.1,0.5,0.2,0.6c0.1,0.2,0.3,0.3,0.5,0.4c0.2,0.1,0.5,0.1,1,0.1h1.5 v-0.1h-0.2c-0.2,0-0.3,0-0.4-0.1c-0.1-0.1-0.1-0.2-0.1-0.5v-2.7c0-0.2,0-0.4,0.1-0.5c0.1-0.1,0.2-0.2,0.4-0.2h0.2v-0.1H56v0.1h0.2 c0.2,0,0.3,0,0.4,0.1c0.1,0.1,0.1,0.2,0.1,0.5v1.2c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c-0.1,0-0.2,0-0.3,0l-1.4-2h-1.1v0.1 c0.2,0,0.4,0.1,0.6,0.2C54.4,80.9,54.5,81,54.7,81.3" />{' '}
              <path d="M50.9,81.4l1.5,3.3h1.2v-0.1c-0.2,0-0.3,0-0.4-0.1c-0.1,0-0.1-0.1-0.2-0.2c0-0.1-0.1-0.2-0.1-0.4v-2.8 c0-0.2,0-0.4,0.1-0.5c0.1-0.1,0.2-0.2,0.4-0.2h0.1v-0.1h-1.5v0.1h0.1c0.2,0,0.3,0,0.4,0.1c0.1,0.1,0.1,0.2,0.1,0.5V84l-1.6-3.5H51 L49.4,84v-2.8c0-0.2,0-0.4,0.1-0.5c0.1-0.1,0.2-0.2,0.4-0.2H50v-0.1h-1.8v0.1h0.1c0.2,0,0.3,0,0.4,0.1c0.1,0.1,0.1,0.2,0.1,0.5V84 c0,0.2,0,0.4-0.1,0.5c-0.1,0.1-0.2,0.2-0.4,0.2h-0.1v0.1h1.2L50.9,81.4z" />{' '}
              <path d="M46.6,82.6C46.6,82.6,46.6,82.6,46.6,82.6c0.1,0,0.1,0,0.2,0v1.8c-0.2,0-0.4,0.1-0.5,0.1 c-0.3,0-0.5-0.1-0.7-0.2c-0.2-0.2-0.2-0.4-0.2-0.6c0-0.3,0.1-0.5,0.3-0.7C45.9,82.7,46.2,82.6,46.6,82.6 M44.8,81.3l0.9,1.2 c-0.4,0.1-0.6,0.2-0.8,0.4c-0.2,0.2-0.3,0.4-0.3,0.7c0,0.2,0.1,0.5,0.2,0.6c0.1,0.2,0.3,0.3,0.5,0.4c0.2,0.1,0.5,0.1,1,0.1H48 v-0.1h-0.2c-0.2,0-0.3,0-0.4-0.1c-0.1-0.1-0.1-0.2-0.1-0.5v-2.7c0-0.2,0-0.4,0.1-0.5c0.1-0.1,0.2-0.2,0.4-0.2H48v-0.1h-1.8v0.1 h0.2c0.2,0,0.3,0,0.4,0.1c0.1,0.1,0.1,0.2,0.1,0.5v1.2c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c-0.1,0-0.2,0-0.3,0l-1.4-2h-1.1v0.1 c0.2,0,0.4,0.1,0.6,0.2C44.5,80.9,44.6,81,44.8,81.3" />{' '}
              <path d="M41.1,84.7v-0.1c-0.1,0-0.2,0-0.3,0c-0.1,0-0.1-0.1-0.1-0.1c0,0,0-0.1,0-0.2c0-0.1,0.1-0.2,0.2-0.4l0.8-1.3 l0.9,1.4c0.1,0.2,0.2,0.3,0.2,0.4c0,0.1,0,0.2-0.1,0.2c-0.1,0-0.2,0.1-0.3,0.1h-0.1v0.1h1.8v-0.1c-0.1,0-0.1,0-0.2,0 c-0.2-0.1-0.3-0.1-0.3-0.2c0-0.1-0.1-0.2-0.3-0.4l-1.2-1.8v-1c0-0.2,0-0.4,0.1-0.5c0.1-0.1,0.2-0.2,0.4-0.2h0.1v-0.1H41v0.1h0.1 c0.2,0,0.3,0,0.4,0.1c0.1,0.1,0.1,0.2,0.1,0.5v1.1l-1,1.6c-0.1,0.2-0.2,0.3-0.3,0.4c-0.1,0.1-0.2,0.2-0.3,0.2 c-0.1,0-0.2,0.1-0.2,0.1h-0.1v0.1H41.1z" />{' '}
            </g>{' '}
          </g>
        </svg>
        <p>{`Primary Club is a creative agency based in Los Angeles and 
        established in 2018. We work on a wide range of projects for 
        beauty and lifestyle sectors with a focus on Branding and 
        Marketing. Feel free to get in touch for more information, 
        see below.`}</p>
        <p>
          Email:
          <br />
          <a href={`mailto:info@primaryclub.club`}>info@primaryclub.club</a>
        </p>
        <p>
          Location:
          <br />
          1200 Santee St #902
          <br />
          Los Angeles, California
        </p>
        <p>{`Selected Client List:
Apple, Converse, Nike, WESC, 
MAC Cosmetics, Mohawk General Store, 
Smock, Five Spice, Little Beach`}</p>
        <Footer>© COPYRIGHT MMXX PRIMARY CLUB. ALL RIGHTS RESERVED</Footer>
      </Wrapper>
    </WebsiteDefaultPage>
  );
};

export default PageContainer;
