// @flow

import React from 'react';
import type { Node } from 'react';
import styled, { css } from 'styled-components';

import { Link } from 'components/Gui';
import type { LinkProps } from 'components/Gui/links/Link';
import { buttonDefault, Color, flex, hoverMultiple, Width } from 'styles';

const Themes = {
  PRIMARY: 'PRIMARY',
  FILLED: 'FILLED',
};

const Wrapper = styled.div`
  height: ${({ mobileLarge }) => (mobileLarge ? 48 : 36)}px;
  max-width: 100%;
  ${({ mobileLarge }) =>
    mobileLarge &&
    css`
      width: 320px;
      @media print, screen and (min-width: ${Width.BREAK_MD}px) {
        display: inline-block;
        width: unset;
        height: 36px;
      }
    `};
  a,
  button {
    ${flex()};
    padding: 0 ${({ padding }) => padding}px;
    border-radius: 30px;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    ${buttonDefault};
    ${({ theme }) =>
      theme === Themes.PRIMARY &&
      css`
        ${hoverMultiple([
          {
            prop: 'color',
            base: Color.BLACK_SOUL,
            hover: Color.WHITE,
          },
          {
            prop: 'background',
            base: 'none',
            hover: Color.PURPLE_FIGURE,
          },
          {
            prop: 'border',
            base: `2px solid ${Color.YELLOW_MELLOW}`,
            hover: `2px solid ${Color.PURPLE_FIGURE}`,
          },
        ])}
      `}
    ${({ theme }) =>
      theme === Themes.FILLED &&
      css`
        ${hoverMultiple([
          {
            prop: 'color',
            base: Color.BLACK_SOUL,
            hover: Color.WHITE,
          },
          {
            prop: 'background',
            base: Color.YELLOW_MELLOW,
            hover: Color.PURPLE_FIGURE,
          },
        ])}
      `}
  }
`;

export type Props = {
  theme?: $Keys<typeof Themes>,
  padding?: number,
  mobileLarge?: boolean,
  children: Node,
};

const Button = ({ theme = Themes.PRIMARY, padding = 50, mobileLarge = false, children, ...rest }: LinkProps & Props) => (
  <Wrapper theme={theme} mobileLarge={mobileLarge} padding={padding}>
    <Link {...rest}>{children}</Link>
  </Wrapper>
);

export default Button;
