// @flow

import React, { useCallback, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import { Link } from 'components/Gui';
import type { LinkProps } from 'components/Gui/links/Link';
import { absolute, buttonDefault, Color, flex, hoverChild, hoverMultiple, Transition, Width } from 'styles';
import { RightSquaredArrow } from 'components/Svg';

const Wrapper = styled.div`
  position: relative;
  height: 48px;
  max-width: 100%;
  width: 320px;
  @media print, screen and (min-width: ${Width.BREAK_MD}px) {
    display: inline-block;
    width: unset;
    height: 36px;
  }
  button {
    position: relative;
    z-index: 3;
    ${flex()};
    border-radius: 30px;
    padding: 0 ${({ padding }) => padding}px;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    ${buttonDefault};
    ${hoverMultiple([
      {
        prop: 'color',
        base: Color.BLACK_SOUL,
        hover: Color.WHITE,
      },
      {
        prop: 'background',
        base: 'none',
        hover: Color.PURPLE_FIGURE,
      },
      {
        prop: 'border',
        base: `2px solid ${Color.YELLOW_MELLOW}`,
        hover: `2px solid ${Color.PURPLE_FIGURE}`,
      },
    ])}
    span {
      transition: transform ${Transition.DEFAULT};
    }
    ${({ inputShowing, padding }) =>
      inputShowing &&
      css`
        z-index: 0;
        border: 2px solid transparent !important;
        background: none !important;
        color: ${Color.BLACK_SOUL} !important;

        span {
          transform-origin: 50% 0 0;
          transform: translate(0, -1.6rem) scale(0.9);

          @media print, screen and (min-width: ${Width.BREAK_MD}px) {
            transform-origin: 0 0 0;
            transform: translate(-${padding}px, -1.7rem) scale(0.9);
          }
        }
      `}
  }
  input {
    ${absolute('unset', 0, '2px', 0)};
    height: 2.4rem;
    z-index: ${({ inputShowing }) => (inputShowing ? 1 : 0)};
    ${buttonDefault};
    font-size: 1.6rem;
    ${flex('flex-start', 'center')};
    text-transform: uppercase;
    text-align: center;
    width: 100%;

    @media print, screen and (min-width: ${Width.BREAK_MD}px) {
      text-indent: 1px;
      text-align: left;
    }
  }
`;

const Line = styled.div`
  transform-origin: 0 50% 0;
  transition: transform ${Transition.DEFAULT} 150ms;
  ${absolute('unset', 0, 0, 0)};
  height: 1px;
  background: ${Color.BLACK_SOUL};
  transform: scaleX(${({ inputShowing }) => (inputShowing ? 1 : 0)});
`;

const Arrow = styled.div`
  ${absolute('unset', 0, '2px', 'unset')};
  z-index: ${({ inputShowing }) => (inputShowing ? 2 : 0)};
  width: 32px;
  height: 2.4rem;
  ${flex()};
  transition: opacity ${Transition.DEFAULT} ${Transition.TIME_DEFAULT};
  opacity: ${({ inputShowing }) => (inputShowing ? 1 : 0)};
  cursor: pointer;
  ${hoverChild('g', 'stroke', Color.YELLOW_MELLOW, Color.BLACK_SOUL)};
  svg {
    width: 17px;
  }
`;

type Props = {
  padding?: number,
  type?: string,
  buttonLabel?: string,
  name: string,
  maxLength?: number,
  onSubmit: (value: string) => void,
};

const InputButton = ({
  padding = 50,
  type = 'text',
  buttonLabel = 'Activate Input',
  name,
  onSubmit,
  maxLength = null,
  children,
  ...rest
}: LinkProps & Props) => {
  const [inputShowing, setInputShowing] = useState(false);
  const [value, setValue] = useState(false);
  const inputEl = useRef();
  const onButtonClicked = useCallback(() => {
    setInputShowing(true);
    inputEl.current.focus();
  }, [setInputShowing, inputEl]);
  const submitValue = useCallback(() => {
    onSubmit(value);
  }, [value, onSubmit]);
  return (
    <Wrapper padding={padding} inputShowing={inputShowing}>
      <Link id={name} tabIndex={inputShowing ? -1 : null} label={buttonLabel} onClick={onButtonClicked} {...rest}>
        <span>{children}</span>
      </Link>
      <Line inputShowing={inputShowing} />
      <input
        aria-labelledby={name}
        type={type}
        ref={inputEl}
        tabIndex={inputShowing ? 0 : -1}
        onChange={({ target: { value } }) => setValue(value)}
        name={name}
        maxLength={maxLength}
      />
      <Arrow inputShowing={inputShowing} role="button" tabIndex={inputShowing ? 0 : null} aria-label={'Submit'} onClick={submitValue}>
        {RightSquaredArrow}
      </Arrow>
    </Wrapper>
  );
};

export default InputButton;
