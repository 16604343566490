// @flow

import React, { useState, useEffect, useCallback } from 'react';
import type { Node } from 'react';
import styled from 'styled-components';

import { useHammerSwipe, useIntersectionObserver } from 'hooks';
import { flex } from 'styles';

function normalizeIndex(index: number, length: number): number {
  if (index < 0) return length + index;
  if (index > length - 1) return index - length;
  return index;
}

function setMinimumLength(items: any[]): any[] {
  if (items.length < 5) return setMinimumLength([...items, ...items]);
  else return items;
}

function unshift(arr: any[]): any[] {
  const last = arr.pop();
  return [last, ...arr];
}

function shift(arr: any[]): any[] {
  const first = arr.shift();
  return [...arr, first];
}

export type CarouselConsumerProps = {
  currentIndex: number,
  slideContent: any[],
  slidePositionX: number[],
  slideOpacity: number[],
  onScreen: boolean,
  changeSlide: () => void,
};

const Wrapper = styled.section`
  ${flex()};
  width: 100%;
`;

type Props = {
  children: (props: CarouselConsumerProps) => Node,
  label: string,
  data: any[],
};

const Carousel = ({ children, label, data, ...rest }: Props) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [content, setContent] = useState([]);
  const [slideContent, setSlideContent] = useState([]);
  const [slidePositionX, setSlidePositionX] = useState([-2, -1, 0, 1, 2]);
  const [slideOpacity, setSlideOpacity] = useState([0, 1, 1, 1, 0]);
  useEffect(() => {
    const content = setMinimumLength(data);
    setContent(content);
    setSlideContent([
      content[normalizeIndex(-2, content.length)],
      content[normalizeIndex(-1, content.length)],
      content[0],
      content[normalizeIndex(1, content.length)],
      content[normalizeIndex(2, content.length)],
    ]);
  }, [data]);
  const changeSlide = useCallback(
    (left: boolean = false) => {
      const index = normalizeIndex(currentIndex + (left ? 1 : -1), content.length);
      const newPositionX = left ? unshift(slidePositionX) : shift(slidePositionX);
      setSlidePositionX(newPositionX);
      setSlideOpacity(left ? unshift(slideOpacity) : shift(slideOpacity));
      if (left) {
        setSlideContent(
          slideContent.reduce((acc: any[], slide: any, contentIndex: number) => {
            if (newPositionX[contentIndex] === 2) {
              return [...acc, content[normalizeIndex(index + 2, content.length)]];
            }
            return [...acc, slide];
          }, [])
        );
      } else {
        setSlideContent(
          slideContent.reduce((acc: any[], slide: any, contentIndex: number) => {
            if (newPositionX[contentIndex] === -2) {
              return [...acc, content[normalizeIndex(index - 2, content.length)]];
            }
            return [...acc, slide];
          }, [])
        );
      }
      setCurrentIndex(index);
    },
    [content, currentIndex, slideContent, slideOpacity, slidePositionX]
  );
  const hammerRef = useHammerSwipe(changeSlide, () => changeSlide(true));
  const [observerRef, onScreen] = useIntersectionObserver();
  const setRefs = useCallback(
    ref => {
      hammerRef.current = ref;
      observerRef.current = ref;
    },
    [hammerRef, observerRef]
  );
  return (
    <Wrapper aria-roledescription="carousel" role="region" ref={setRefs} aria-label={label}>
      {children({ currentIndex, slideContent, slidePositionX, slideOpacity, onScreen, changeSlide })}
    </Wrapper>
  );
};

export default Carousel;
