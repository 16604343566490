// @flow

import React, { useEffect } from 'react';
import type { Node } from 'react';
import { Location } from '@reach/router';
import styled, { ThemeProvider } from 'styled-components';

import LoadFonts from 'fonts/load-fonts/WebsiteOld';
import { Metadata } from 'components/Gui';
import type { PageMetaData } from 'components/Gui/Metadata';
import type { PageConfig, FooterLegal } from 'contexts/page-context';
import { useSetPageState } from 'contexts/page-context';
import { maxWidthContainer, Width } from 'styles';

const Wrapper = styled.div`
  ${maxWidthContainer(Width.XL)};
`;

const WebsiteOldPage = ({
  metaData,
  page,
  footerLegal = {},
  children,
}: {
  metaData: PageMetaData,
  page: PageConfig,
  footerLegal?: FooterLegal,
  children: Node,
}) => {
  const setPageState = useSetPageState();
  if (!footerLegal) {
  }
  useEffect(() => {
    setPageState({
      page,
      footerLegal,
    });
  }, [setPageState, page, footerLegal]);
  return (
    <>
      <Location>{props => <Metadata data={metaData} {...props} />}</Location>
      <LoadFonts />
      <ThemeProvider theme={{ mode: 'figure' }}>
        <Wrapper>{children}</Wrapper>
      </ThemeProvider>
    </>
  );
};

export default WebsiteOldPage;
