// @flow

import React from 'react';
import type { Node } from 'react';
import styled from 'styled-components';
import { absolute } from 'styles';

const Wrapper = styled.div`
  ${absolute()};
  z-index: 0;
  ${({ background }) => background && `background: ${background}`};
`;

const AbsoluteBackground = ({ children, ...rest }: { children?: Node, background?: string }) => (
  <Wrapper {...rest}>{children && { children }}</Wrapper>
);

export default AbsoluteBackground;
