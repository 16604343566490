// @flow

import React from 'react';

import { Color } from 'styles';

export const RightChevronArrow = (
  <svg viewBox="0 0 7 12" fill="none">
    <title>Up Arrow</title>
    <path d="M1 1L6 6L1 11" stroke={Color.YELLOW_MELLOW} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export const RightRoundArrow = (
  <svg viewBox="0 0 19 14" fill="none">
    <title>Right Arrow</title>
    <path d="M16.5 7L2 7" stroke={Color.YELLOW_MELLOW} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M12 2L17 7L12 12" stroke={Color.YELLOW_MELLOW} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export const RightSquaredArrow = (
  <svg viewBox="0 0 17 14" fill="none">
    <title>Right Arrow</title>
    <g stroke={Color.YELLOW_MELLOW}>
      <path d="m10 1 6 6-6 6" />
      <path d="m0 7h16" />
    </g>
  </svg>
);
