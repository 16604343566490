// @flow

import { useEffect, useRef } from 'react';

import { Detection } from 'Constants';

const Hammer = Detection.IS_BROWSER ? require('hammerjs') : null;

const useHammerSwipe = (onRight, onLeft) => {
  const elRef = useRef(null);
  const hammerRef = useRef(null);
  useEffect(() => {
    if (Detection.IS_BROWSER) {
      if (elRef.current) {
        hammerRef.current = new Hammer(elRef.current);
        hammerRef.current.on('swipe', ({ deltaX }) => {
          deltaX > 0 ? onRight() : onLeft();
        });
      }
    }
    return () => {
      if (hammerRef.current) hammerRef.current.destroy();
    };
  }, [elRef, onLeft, onRight]);
  return elRef;
};

export default useHammerSwipe;
