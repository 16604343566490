import React from 'react';
import Helmet from 'react-helmet';
import { createGlobalStyle } from 'styled-components';

import Gothic300 from 'fonts/gothic-a1-v8-latin-300.woff2';
import Gothic300Fallback from 'fonts/gothic-a1-v8-latin-300.woff';
import Gothic400 from 'fonts/gothic-a1-v8-latin-regular.woff2';
import Gothic400Fallback from 'fonts/gothic-a1-v8-latin-regular.woff';
import Gothic500 from 'fonts/gothic-a1-v8-latin-500.woff2';
import Gothic500Fallback from 'fonts/gothic-a1-v8-latin-500.woff';
import Gothic700 from 'fonts/gothic-a1-v8-latin-700.woff2';
import Gothic700Fallback from 'fonts/gothic-a1-v8-latin-700.woff';
import Poppins400 from 'fonts/poppins-v8-latin-regular.woff2';
import Poppins400Fallback from 'fonts/poppins-v8-latin-regular.woff';
import Poppins700 from 'fonts/poppins-v8-latin-700.woff2';
import Poppins700Fallback from 'fonts/poppins-v8-latin-700.woff';
import { buttonDefault, Color, Fonts, h1Default, h2Default, h3Default, h4Default, h5Default, hover, pDefault } from 'styles';

// https://google-webfonts-helper.herokuapp.com/fonts

const FontStyles = createGlobalStyle`

  @font-face {
    font-family: 'Gothic A1';
    font-style: normal;
    font-weight: 300;
    src: local('Gothic A1 Light'), local('GothicA1-Light'),
          url(${Gothic300}) format('woff2'),
          url(${Gothic300Fallback}) format('woff');
  }
  @font-face {
    font-family: 'Gothic A1';
    font-style: normal;
    font-display: block;
    font-weight: 400;
    src: local('Gothic A1 Regular'), local('GothicA1-Regular'),
         url(${Gothic400}) format('woff2'),
         url(${Gothic400Fallback}) format('woff');
  }
  @font-face {
    font-family: 'Gothic A1';
    font-style: normal;
    font-weight: 500;
    src: local('Gothic A1 Medium'), local('GothicA1-Medium'),
         url(${Gothic500}) format('woff2'),
         url(${Gothic500Fallback}) format('woff');
  }
  @font-face {
    font-family: 'Gothic A1';
    font-style: normal;
    font-display: block;
    font-weight: 700;
    src: local('Gothic A1 Bold'), local('GothicA1-Bold'),
         url(${Gothic700}) format('woff2'),
         url(${Gothic700Fallback}) format('woff');
  }

  @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-display: block;
    font-weight: 400;
    src: local('Poppins Regular'), local('Poppins-Regular'),
         url(${Poppins400}) format('woff2'),
         url(${Poppins400Fallback}) format('woff');
  }

  @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-display: block;
    font-weight: 700;
    src: local('Poppins Bold'), local('Poppins-Bold'),
         url(${Poppins700}) format('woff2'),
         url(${Poppins700Fallback}) format('woff');
  }
  
  h1, h2, h3, h4, h5, h6, p, li, button, div {
    color: ${Color.BLACK_SOUL};
  }
  
  a {
    ${hover('color', Color.BLACK_SOUL, Color.PURPLE_MAIN)};
  }
  
  h1, h2, h3, h4 {
    ${Fonts.POPPINS};
    font-weight: 700;
  }
  
  h5, h6, p, li, div {
    ${Fonts.GOTHIC};
    font-weight: 400;
  }
  
  h1 {
    ${h1Default};
  }
  
  h2 {
    ${h2Default};
  }
  
  h3 {
    ${h3Default};
  }
  
  h4 {
    ${h4Default};
  }
  
  h5 {
    ${h5Default};
  }
  
  p, div {
    ${pDefault};
  }
  
  h1, h3, h4, h5, h6 li, p {
    margin-bottom: 10px;
  }
  
  h2 {
    margin-bottom: 48px;
  }
  
  button {
    ${buttonDefault};
  }
  
  b, strong {
   font-weight: 700;
  }
`;

const LoadFonts = () => (
  <>
    <FontStyles />
    <Helmet>
      <link rel="preload" as="font" type="font/woff2" href={Gothic400} crossOrigin="anonymous" />
      <link rel="preload" as="font" type="font/woff2" href={Gothic500} crossOrigin="anonymous" />
      <link rel="preload" as="font" type="font/woff2" href={Gothic700} crossOrigin="anonymous" />
    </Helmet>
  </>
);

export default LoadFonts;
