// @flow

import React from 'react';
import type { Node } from 'react';
import styled from 'styled-components';

import { Link } from 'components/Gui';

const Li = styled.li`
  list-style: none;
`;

const MenuItem = ({ url, label, children, ...rest }: { url: string, label: string, className?: string, children: Node }) => (
  <Li role="none" {...rest}>
    <Link url={url} label={label} tabIndex="-1">
      {children}
    </Link>
  </Li>
);

export default MenuItem;
