// @flow

import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Helmet from 'react-helmet';

import { titleTemplates } from 'data/global/metadata';
import type { ImageToWebPFragment } from 'graphql-types';
import { usePageState } from 'contexts';

export type PageMetaData = {
  title: string,
  description: string,
  image?: ImageToWebPFragment,
};

type MetadataProps = {
  data: PageMetaData,
  location: Location,
};

const Metadata = (props: MetadataProps) => {
  const {
    data: { title, description, image },
    location: { origin, pathname },
  } = props;
  const { defaultImage } = useStaticQuery(graphql`
    query {
      defaultImage: file(relativeDirectory: { eq: "global/metadata" }, name: { eq: "default-share-image" }) {
        ...ImageToWebP
      }
    }
  `);
  const {
    page: { product },
  } = usePageState();
  const template = titleTemplates[product];
  if (!template) throw new Error(`No title template set for ${product}`);
  if (!title) throw new Error(`No title set for ${pathname}`);
  if (!description) throw new Error(`No description set for ${pathname}`);
  return (
    <Helmet
      title={title}
      titleTemplate={`%s | ${template}`}
      meta={[
        {
          name: 'description',
          content: description,
        },
        {
          name: 'og:type',
          content: 'website',
        },
        {
          name: 'og:url',
          content: `${origin}${pathname}`,
        },
        {
          name: 'og:title',
          content: title,
        },
        {
          name: 'og:description',
          content: description,
        },
        {
          name: 'og:image',
          content: image?.childImageSharp?.original?.src ?? defaultImage.childImageSharp.original.src,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: description,
        },
      ]}>
      <html lang="en" />
    </Helmet>
  );
};

export default Metadata;
