// @flow

import React, { useRef, useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import styled from 'styled-components';

import { displayDesktop, displayMobile, displayTablet } from 'styles';
import { Detection } from 'Constants';

const Wrapper = styled.div`
  flex: 0 0 auto;
  height: ${({ height }) => height};
  width: ${({ width }) => width};
`;

const Mobile = styled(Wrapper)`
  ${displayMobile};
`;

const Tablet = styled(Wrapper)`
  ${displayTablet};
`;

const Desktop = styled(Wrapper)`
  ${displayDesktop};
`;

const WidgetProps = {
  'data-theme': 'light',
  'data-locale': 'en-US',
  'data-businessunit-id': process.env.GATSBY_TRUSTPILOT_BUSINESS_UNIT_ID,
};

type Props = {
  height: {
    mobile: string,
    tablet: string,
    desktop: string,
  },
  width: {
    mobile: string,
    tablet: string,
    desktop: string,
  },
  templateId?: {
    mobile: string,
    tablet: string,
    desktop: string,
  },
  className?: string,
};

const TrustpilotStarsBox = ({
  height: { mobile, tablet, desktop },
  width: { mobile: mWidth, tablet: tWidth, desktop: dWidth },
  templateId: { mobile: mTemplate, tablet: tTemplate, desktop: dTemplate } = {
    mobile: '5406e65db0d04a09e042d5fc',
    tablet: '53aa8807dec7e10d38f59f32',
    desktop: '53aa8807dec7e10d38f59f32',
  },
  className = '',
  ...rest
}: Props) => {
  const ref = useRef(null);
  const refTablet = useRef(null);
  const refDesktop = useRef(null);
  const [trustClassName, setClassName] = useState(null);
  useEffect(() => {
    if (Detection.IS_BROWSER) {
      if (window.Trustpilot) {
        window.Trustpilot.loadFromElement(ref.current, true);
        window.Trustpilot.loadFromElement(refTablet.current, true);
        window.Trustpilot.loadFromElement(refDesktop.current, true);
      }
      setClassName('trustpilot-widget');
    }
  }, [setClassName]);
  return (
    <>
      <Helmet>
        <script type="text/javascript" src="https://widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js" async defer />
        <link rel="preconnect" href={`https://widget.trustpilot.com`} />
      </Helmet>
      <Mobile
        ref={ref}
        className={className ? `${className} ${trustClassName}` : trustClassName}
        data-template-id={mTemplate}
        height={mobile}
        data-style-height={mobile}
        width={mWidth}
        data-style-width={mWidth}
        {...WidgetProps}
        {...rest}
      />
      <Tablet
        ref={refTablet}
        className={className ? `${className} ${trustClassName}` : trustClassName}
        data-template-id={tTemplate}
        height={tablet}
        data-style-height={tablet}
        width={tWidth}
        data-style-width={tWidth}
        {...WidgetProps}
        {...rest}
      />
      <Desktop
        ref={refDesktop}
        className={className ? `${className} ${trustClassName}` : trustClassName}
        data-template-id={dTemplate}
        height={desktop}
        data-style-height={desktop}
        width={dWidth}
        data-style-width={dWidth}
        {...WidgetProps}
        {...rest}
      />
    </>
  );
};

export default TrustpilotStarsBox;
