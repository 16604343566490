// @flow

import React from 'react';

export const Logo = (
  <svg viewBox="0 0 85 18">
    <title>Figure Logo</title>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 0.34375H10.2365V3.73295H0V0.34375ZM0 17.3187V7.13493H10.2365V10.5241H3.40676V17.3187H0Z"
    />
    <path d="M19 0H15V17H19V0Z" />
    <path d="M31.5272 7.20981V10.6431H33.6136V11.3624C33.6136 13.1117 32.2173 14.5341 30.5 14.5341C28.7827 14.5341 27.3864 13.1117 27.3864 11.3624V6.62125C27.3864 4.87193 28.7827 3.44959 30.5 3.44959C31.1259 3.44959 31.7198 3.62943 32.2494 3.9891C32.3938 4.08719 32.5383 4.20163 32.6667 4.33243L32.779 4.43052L35.1222 1.9455L35.0099 1.84741C34.7852 1.61853 34.5284 1.40599 34.2716 1.22616C34.2235 1.19346 34.1914 1.16076 34.1432 1.14441H34.1272C34.0951 1.11172 34.0469 1.09537 34.0148 1.06267C32.9556 0.359673 31.7519 0 30.5 0C26.921 0 24 2.95913 24 6.62125V11.3787C24 15.0245 26.9049 18 30.5 18C34.079 18 37 15.0409 37 11.3787V7.20981H31.5272Z" />
    <path d="M49.5267 1.59537e-08V11.2751V11.4256C49.5267 13.1152 48.1705 14.487 46.5 14.487C44.8295 14.487 43.4733 13.1152 43.4733 11.4256V11.2751V1.59537e-08H40V11.2751V11.4256C40 15.0558 42.9109 18 46.5 18C50.0394 18 52.9173 15.1394 53 11.5762V11.4256V11.2751V1.59537e-08H49.5267Z" />
    <path d="M66.3478 16.9838H70L66.996 10.4876C68.6719 9.51806 69.7945 7.69202 69.7945 5.60741C69.7945 2.52091 67.3281 0 64.3083 0H64.166H61.336H59.9289H58V17H61.3202V11.1987H63.6443L66.3478 16.9838ZM61.336 3.39354H64.166H64.3083C65.4941 3.39354 66.4743 4.37928 66.4743 5.60741C66.4743 6.81939 65.5099 7.82129 64.3083 7.82129H64.166H61.336V3.39354Z" />
    <path d="M85 3.4097V1.54111e-08H75V17H85V13.5903H78.3439V10.1968H85V6.80323H78.3439V3.4097H85Z" />
  </svg>
);

export const LogoOneLetter = (
  <svg viewBox="0 0 13 23" fill="none">
    <title>Figure Logo</title>
    <path d="M0 9.16635L0 22.1664H4.32647L4.32647 13.4928L13 13.4928V9.16635L0 9.16635Z" />
    <path d="M13 0.808594L0 0.808594L0 5.45145L13 5.45145V0.808594Z" />
  </svg>
);
