// @flow

import { PageType } from 'Constants';

const TitleTemplate = 'Primary Club';

export const titleTemplates = {
  [PageType.PRODUCT.NONE]: TitleTemplate,
  [PageType.PRODUCT.HELOC]: TitleTemplate,
  [PageType.PRODUCT.SLR]: TitleTemplate,
  [PageType.PRODUCT.MORTGAGE_REFI]: TitleTemplate,
};
