// @flow

import React from 'react';
import type { Node } from 'react';
import styled, { css } from 'styled-components';
import { bottomPadding, Color, flex, h15, h20, h203640, topMargin, Width } from 'styles';
import { AbsoluteBackground as BaseAbsoluteBackground } from 'components/Gui';

const Wrapper = styled.div`
  position: relative;
  width: calc(100% + 40px);
  transform: translateX(-20px);
  max-width: 760px;
  margin: 0 auto;
  padding-bottom: 24px;
  ${topMargin(48, 80, 80)};
  ${bottomPadding(24, 32, 48)};

  @media print, screen and (min-width: 420px) {
    width: 100%;
    transform: none;
  }
`;

const AbsoluteBackground = styled(BaseAbsoluteBackground)`
  border-radius: 2px;
  width: calc(50% - ${({ margins }) => `${margins.mobile}px`} / 2);

  @media print, screen and (min-width: ${Width.BREAK_MD}px) {
    border-radius: 4px;
    width: calc(50% - ${({ margins }) => `${margins.tablet}px`} / 2);
  }

  @media print, screen and (min-width: ${Width.BREAK_XL}px) {
    width: calc(50% - ${({ margins }) => `${margins.desktop}px`} / 2);
  }

  ${({ right }) =>
    right
      ? css`
          left: unset;
        `
      : css`
          right: unset;
        `}
`;

const Row = styled.div`
  ${flex('space-between', 'stretch')};

  * {
    text-align: center;
  }
`;

const Cell = styled.div`
  position: relative;
  flex: 0 0 auto;
  box-sizing: border-box;
  padding: 0 10px;
  width: calc(50% - ${({ margins }) => `${margins.mobile}px`} / 2);

  @media print, screen and (min-width: ${Width.BREAK_MD}px) {
    width: calc(50% - ${({ margins }) => `${margins.tablet}px`} / 2);
  }

  @media print, screen and (min-width: ${Width.BREAK_XL}px) {
    width: calc(50% - ${({ margins }) => `${margins.desktop}px`} / 2);
  }

  &:first-of-type {
    * {
      color: ${Color.WHITE};
      text-align: center;
    }
  }
`;

const TitleCell = styled(Cell)`
  height: 72px;
  ${flex()};

  h3 {
    margin-bottom: 0;
    line-height: 1;
    font-size: 1.8rem;

    @media print, screen and (min-width: 420px) {
      ${h20};
    }
  }
`;

const ItemCell = styled(Cell)`
  ${flex('flex-start', 'center')};
  flex-direction: column;

  h3,
  h4 {
    padding-top: 24px;

    ${({ extraPadding }) =>
      extraPadding &&
      css`
        @media print, screen and (min-width: ${Width.BREAK_MD}px) {
          padding-top: 32px;
        }
      `}
  }
  h3 {
    ${h203640};
  }
  span {
    display: block;
    ${h15};

    @media print, screen and (min-width: ${Width.BREAK_MD}px) {
      margin-top: 4px;
      ${h20};
    }

    @media print, screen and (min-width: ${Width.BREAK_XL}px) {
      ${h20};
    }
  }
`;

const WhiteLineBackground = styled(BaseAbsoluteBackground)`
  bottom: -1px;
  top: unset;
  height: 2px;
`;

const FirstCellBackground = styled(BaseAbsoluteBackground)`
  bottom: unset;
  height: 80%;
  opacity: 0.08;
`;

type Props = {
  margins?: {
    mobile: number,
    tablet: number,
    desktop: number,
  },
  titles: Node | string[],
  rows: Array<Node | string>[],
};

const TwoColTable = ({ margins = { mobile: 9, tablet: 12, desktop: 12 }, titles, rows, ...rest }: Props) => (
  <Wrapper role="table" {...rest}>
    <AbsoluteBackground
      background={`linear-gradient(180deg, rgba(161, 174, 229, 0.5) 0%, rgba(255, 255, 255, 0) 50%), #290D6D`}
      margins={margins}
    />
    <AbsoluteBackground background={Color.BLURPLE_LIGHT} right margins={margins} />
    <div role="rowgroup">
      <Row role="row">
        {titles.map((title, index) => (
          <TitleCell key={`TableTitle-${index}`} role="columnheader" margins={margins}>
            {title}
            {index === 1 && <WhiteLineBackground background={Color.WHITE} />}
          </TitleCell>
        ))}
      </Row>
    </div>
    <div role="rowgroup">
      {rows.map((row, index) => (
        <Row role="row" key={`TableRow-${index}`}>
          {row.map((rowItem, itemIndex) => (
            <ItemCell role="cell" key={`TableRowItem-${itemIndex}`} margins={margins} extraPadding={index === 0}>
              {index === 0 && itemIndex === 0 && (
                <FirstCellBackground background={`linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 50%)`} />
              )}
              {rowItem}
            </ItemCell>
          ))}
        </Row>
      ))}
    </div>
  </Wrapper>
);

export default TwoColTable;
