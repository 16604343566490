// @flow

import React, { useState } from 'react';
import type { Node } from 'react';
import styled from 'styled-components';
import { Color, flex, Transition, Width } from 'styles';

const Button = styled.button`
  width: 100%;
  ${flex('space-between', 'center')};
  margin: 0;
  padding: 0;
  text-align: left;

  h4 {
    margin-bottom: 0;
  }
`;

const Lines = styled.div`
  height: 18px;
  width: 18px;
  margin-left: 20px;
  flex: 0 0 auto;
  position: relative;
  cursor: pointer;

  @media print, screen and (min-width: ${Width.BREAK_MD}px) {
    margin-left: 38px;
  }

  div {
    position: absolute;
    top: 8px;
    left: 0;
    right: 0;
    height: 2px;
    background: ${Color.BLACK};

    &:nth-of-type(1) {
      transform: rotate(90deg);
      transform-origin: 50% 50% 0;
      opacity: ${({ showing }) => (showing ? 0 : 1)};
    }
  }
`;

const Accordion = styled.p`
  height: ${({ showing }) => (showing ? 'auto' : 0)};
  opacity: ${({ showing }) => (showing ? 1 : 0)};
  margin: ${({ showing }) => (showing ? '20px 0 0 0' : 0)};
  transition: ${({ showing }) => (showing ? `opacity ${Transition.DEFAULT}` : 'none')};
  box-sizing: border-box;
  font-size: 1.5rem;
  line-height: 1.5;

  a {
    text-decoration: underline;
  }
`;

const PlusAccordion = ({ q, a }: { q: string | Node, a: string | Node }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <Button onClick={() => setIsOpen(!isOpen)}>
        <h4>{q}</h4>
        <Lines showing={isOpen}>
          <div />
          <div />
        </Lines>
      </Button>
      <Accordion showing={isOpen}>{a}</Accordion>
    </>
  );
};

export default PlusAccordion;
