// @flow

import React from 'react';
import type { Node } from 'react';
import styled from 'styled-components';
import { absolute, Color, flex, p10, p1013, Width } from 'styles';

const Wrapper = styled.div`
  width: 100%;
  > div {
    position: relative;
  }
`;

const Backgrounds = styled.div`
  ${absolute(0, 0, 0, '20%')};
  ${flex('space-between', 'center')};

  @media print, screen and (min-width: ${Width.BREAK_XS}px) {
    left: 25%;
  }

  @media print, screen and (min-width: ${Width.BREAK_MD}px) {
    left: 164px;
  }

  > div {
    width: calc(25% - 3px / 4);
    height: 100%;
    background: ${Color.BLURPLE_LIGHT};

    @media print, screen and (min-width: ${Width.BREAK_MD}px) {
      width: calc(25% - 15px / 4);
    }

    &:nth-of-type(1) {
      background: linear-gradient(233.82deg, rgba(68, 54, 142, 0.43) 0.59%, rgba(255, 255, 255, 0) 99.41%), #290d6d;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }

    &:last-of-type {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }
`;

const Row = styled.div`
  position: relative;
  z-index: 1;
  ${flex('space-between', 'center')};
`;

const Cell = styled.div`
  flex: 0 0 auto;
  width: calc((80% - 4px) / 4);
  margin: 16px 0;
  ${flex()};
  ${p1013};
  display: block;
  text-align: center;
  white-space: pre-line;
  box-sizing: border-box;

  @media print, screen and (min-width: ${Width.BREAK_XS}px) {
    width: calc((75% - 4px) / 4);
  }

  @media print, screen and (min-width: ${Width.BREAK_MD}px) {
    width: calc((100% - 168px) / 4);
  }
  &:first-of-type {
    width: 20%;
    padding-right: 10px;

    @media print, screen and (min-width: ${Width.BREAK_XS}px) {
      width: 25%;
    }

    @media print, screen and (min-width: ${Width.BREAK_MD}px) {
      width: 164px;
      text-align: right;
      padding-right: 24px;
    }
  }
  &:nth-of-type(2) {
    color: ${Color.WHITE};
  }

  @media print, screen and (min-width: ${Width.BREAK_MD}px) {
    margin: 20px 0;
    white-space: unset;
    padding: 0 20px;
  }
`;

const MobileImage = styled.div`
  ${flex()};
  svg {
    width: 13px;

    path {
      fill: ${Color.PURPLE_FIGURE};
    }
  }

  @media print, screen and (min-width: ${Width.BREAK_MD}px) {
    display: none;
  }
`;

const Image = styled.div`
  display: none;

  svg {
    width: 62px;

    path {
      fill: ${Color.PURPLE_FIGURE};
    }
  }

  @media print, screen and (min-width: ${Width.BREAK_MD}px) {
    ${flex()};
  }
`;

const Disclaimers = styled.div`
  margin-left: 20%;
  margin-top: 10px;
  @media print, screen and (min-width: ${Width.BREAK_XS}px) {
    margin-left: 25%;
  }
  @media print, screen and (min-width: ${Width.BREAK_MD}px) {
    margin-left: 168px;
  }
  * {
    ${p10};
  }
`;

type Props = {
  titles: any[],
  rows: Array<Node | string>[],
  disclaimers?: Array<Node | string>,
};

const FiveColTable = ({ titles, rows, disclaimers = [], ...rest }: Props) => (
  <Wrapper role="table" {...rest}>
    <div role="rowgroup">
      <Row role="row">
        {titles.map((title, index) => (
          <Cell key={`TableTitle-${index}`} role="columnheader">
            {title && title.images ? (
              <>
                {title.images.s && <MobileImage>{title.images.s}</MobileImage>}
                {title.images.m && <Image>{title.images.m}</Image>}
              </>
            ) : (
              title
            )}
          </Cell>
        ))}
      </Row>
    </div>
    <div role="rowgroup">
      <Backgrounds>
        <div />
        <div />
        <div />
        <div />
      </Backgrounds>
      {rows.map((row, index) => (
        <Row role="row" key={`TableRow-${index}`}>
          {row.map((rowItem, itemIndex) => (
            <Cell role="cell" key={`TableRowItem-${itemIndex}`}>
              {rowItem}
            </Cell>
          ))}
        </Row>
      ))}
    </div>
    <Disclaimers>
      {disclaimers.map((disclaimer, index) => (
        <div key={`TableDisclaimer-${index}`}>{disclaimer}</div>
      ))}
    </Disclaimers>
  </Wrapper>
);

export default FiveColTable;
