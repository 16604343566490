// @flow

import React from 'react';
import styled from 'styled-components';

import Link from './Link';
import type { LinkProps } from './Link';
import { RightSquaredArrow } from 'components/Svg';
import { Color, hoverChild, inlineFlex, p12 } from 'styles';

const Wrapper = styled.div`
  display: inline-block;
  margin-right: 24px;
  &:last-of-type {
    margin-right: 0;
  }
  ${hoverChild('path', 'stroke', Color.YELLOW_MELLOW, Color.PURPLE_MAIN)};
  a {
    ${p12};
    font-weight: 700;
    ${inlineFlex()};
    svg {
      width: 15px;
      margin-left: 8px;
    }
  }
`;

const ArrowLink = ({ children, ...rest }: LinkProps) => (
  <Wrapper>
    <Link {...rest}>
      {children}
      {RightSquaredArrow}
    </Link>
  </Wrapper>
);

export default ArrowLink;
